import React from "react";
import { connect } from "react-redux";
import { betActions, userActions } from "../../_actions";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Sidebar from "../../components/Sidebar/Sidebar";

class Ledgers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 10,
    };
  }

  componentDidMount() {
    // Call the getProfile API
    this.props.dispatch(userActions.getProfile());

    // Initialize game request data
    let gameReqData = {
      keyWord: "",
      pageNo: this.state.pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: this.state.size,
    };

    // Call the getGamesList API
    this.props.dispatch(userActions.getGamesList(gameReqData));
  }

  componentDidUpdate(prevProps) {
    // Check if the getProfile API has completed and the profile data is available
    if (
      prevProps.users.getProfile !== this.props.users.getProfile &&
      this.props.users.getProfile
    ) {
      const getProfile = this.props.users.getProfile;

      // Once the profile data is available, prepare the object for the second API call
      let obj = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
        userId: getProfile._id,
      };

      // Call the getAllEntriesbyId API with the prepared object
      this.props.dispatch(betActions.getAllEntriesbyId(obj));
    }
  }

  getBatHistory = () => {
    const getProfile = this.props.users.getProfile;
    // Once the profile data is available, prepare the object for the second API call
    let obj = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
      userId: getProfile._id,
    };

    // Call the getAllEntriesbyId API with the prepared object
    this.props.dispatch(betActions.getAllEntriesbyId(obj));

  };

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };

  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  };

  handlePageClick = (data) => {
    const getProfile = this.props.users.getProfile;
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
        userId: getProfile._id,
      };

    
      this.props.dispatch(betActions.getAllEntriesbyId(pagination));
    });
  };

  render() {
    let { bet, users } = this.props;
    let { loading, betLoading, totalBet, tnxTotal, tnxList, allEntries,allEntriesTotal } = bet;
    let { totalGame, gameList } = users;
    const today = new Date().toISOString().split("T")[0];

    console.log(allEntriesTotal)
    return (
      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className="bg-gray-200 w-full min-h-screen flex py-10 ">
          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className=" w-full  space-y-4 mx-auto px-3">
            <div className=" text-xl font-bold border-b-2 border mb-2">
              My statement
            </div>
            <div className="  border grid grid-cols-1 lg:grid-cols-7 divide-x  lg:divide-y-0">
              <div className="col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center">
                <label className="">From Date</label>
                <input
                  type="date"
                  max={today}
                  className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker"
                  required
                  value={this.state.startDate}
                  onChange={this.inputChangeStartDate}
                />
              </div>
              <div className="col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center">
                <label className="">To Date</label>
                <input
                  type="date"
                  max={today}
                  className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker"
                  required
                  value={this.state.endDate}
                  onChange={this.inputChangeEndDate}
                />
              </div>

              <div className="col-span-1 flex flex-col justify-end items-end p-4">
                <label className=""> </label>
                <button
                  onClick={() => this.getBatHistory()}
                  className="py-1.5 px-4 bg-[#3e44d5] text-white text-[0.8125rem] rounded"
                >
                  Submit
                </button>
              </div>
            </div>

            <div className="overflow-hidden ">
              <div className="overflow-hidden w-full ">
                <div className="mb-10 w-full border-2 p-4 overflow-hidden overflow-x-auto overflow-y-auto">
                  <table className="table-auto w-full">
                    <thead>
                      <tr className="bg-gray-100 text-xs ">
                        <th className="px-4 py-2 text-left">Description</th>
                        <th className="px-4 py-2 text-left">Date</th>
                        <th className="px-4 py-2 text-left">Credit</th>
                        <th className="px-4 py-2 text-left">Debit</th>
                        <th className="px-4 py-2 text-left">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allEntries && allEntries.length > 0 ? (
                        allEntries.map((element, index) => (
                          <tr key={index} className="border-t">
                            <td className="px-4 py-2 text-xs">
                              {element?.description || ""}
                            </td>
                            <td className="px-4 py-2 text-xs whitespace-nowrap">
                              {new Date(element?.createdAt).toDateString() || ""}
                            </td>
                            <td className="px-4 py-2 text-xs">
                              {element?.type === "credit"
                                ? element?.amount
                                : "-"}
                            </td>
                            <td className="px-4 py-2 text-xs">
                              {element?.type === "debit"
                                ? element?.amount
                                : "-"}
                            </td>
                            <td className="px-4 py-2 text-xs">
                              {element?.walletAmount || ""}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="px-4 py-2 text-xs text-center">
                            No entries found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {

<nav className="relative z-0 flex mb-10 justify-end  w-76">
  {
    allEntriesTotal && allEntriesTotal > this.state.size ?
      <ReactPaginate
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={allEntriesTotal / this.state.size}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={this.handlePageClick}
        containerClassName={'pagination'}
        pageClassName={'page-cls'}
        activeClassName={'active'}
      />
      : null}
</nav>
}
                
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet,
  };
}

export default connect(mapStateToProps)(Ledgers);
