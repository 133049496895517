
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { MdWindow } from "react-icons/md";
import { TiInfoLarge } from "react-icons/ti";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaStar } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";
import { RiFileList3Line } from "react-icons/ri";
import { MdPassword } from "react-icons/md";
import { RiLogoutBoxLine } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { GiPodiumWinner } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { RiPagesLine } from "react-icons/ri";
import { GiCard10Hearts } from "react-icons/gi";
import { userActions } from "../../_actions";
import { IoBarChartSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import user from "./user.png"
function Sidebar(props) {
  const { opensizebar, setopensizebar } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let selector =useSelector(state=>state)
  let { users } = selector ? selector : {}
  let { getProfile, loading } = users ? users : {}
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [sport_id, setSport_id] = useState(props.sportId || null);


  const onClickMenu = (url) => {
    history.push(url);
    setNavbarOpen(!navbarOpen);
    setopensizebar(false)
  };

  useEffect(() => {

    dispatch(userActions.getProfile())
  }, [opensizebar]);

  const showList = (ele, url) => {
    setSport_id(ele.sport_id);
    let data1 = {
      limit: 1,
      pageno: 1,
      sport_id: ele.sport_id,
    };
    // dispatch(userActions.event_game_list(data1));

    history.push(url + ele.sport_id);
  };

  const handleClickInside = () => {
    // Handle click inside
  };

  const handleLogout = () => {
    confirmAlert({
      title: '',
      message: ` Are you sure you want to Logout?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/login');
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  const handleLogout2 = () => {
    confirmAlert({
      title: '',
      message: ` Are you sure you want to Delete Account?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(userActions.deleteUser())
            localStorage.clear();
            sessionStorage.clear();
            history.push('/login');
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }



// console.log("----<",getProfile)

  return (
    <div className={`shadow-lg relative transition-all flex duration-100 ease-in-out w-${opensizebar ? '72' : '0'} h-screen border-t-8 border-black rounded-t-xl`}>
      <div className={`flex flex-col  rounded-lg transition-all duration-100 ease-in-out    ${opensizebar ? 'md:w-96 w-60' : 'w-0'}  bg-[#3e44d5] overflow-hidden relative h-[95%]`}>
        {/* <div className=" bg-[#f8713e] h-40  w-48 rounded-full absolute -top-28 right-10 "></div> */}
        <div className="flex flex-col flex-1 overflow-x-hidden overflow-y-auto scroll-md">
          <div className="text-[#3B5160]  md:relative">
            {/* <div className=" text-white  py-5 px-2 text-xl font-bold">Navbar</div> */}

            <div className=" flex justify-start border-b border-white mb-2 p-4 gap-2 items-center">
              <img src={user} className=" h-16 mb-2" />
              {/* <FaUser className=" text-5xl text-white h-40 mb-2"/> */}
              <div className="flex flex-col text-white">
              <div className="gap-2 font-semibold">{getProfile && getProfile["userName"] ? getProfile["userName"] : ""}</div>
              <div className="gap-2 font-semibold">{getProfile && getProfile["mobNo"] ? getProfile["mobNo"] : ""}</div>
              </div>
            </div>

            <div className=" px-4 flex flex-col gap-2 ">
              <span onClick={() => onClickMenu('/app/dashboard')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/dashboard" ? "text-white bg-[#f97941]  rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><MdWindow className="text-xl" /></span>
                <span className="font-semibold ">Home</span>
              </span>


              {/* <span onClick={() => onClickMenu('/app/my_profile')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/my_profile" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><FaRegUserCircle className="text-xl" /></span>
                <span className="font-semibold ">MyProfile</span>
              </span> */}


              <span onClick={() => onClickMenu('/app/my-bets')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/my-bets" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><RiFileList3Line className="text-xl" /></span>
                <span className="font-semibold ">Bid History</span>
              </span>



              <span onClick={() => onClickMenu('/app/win_history')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><GiPodiumWinner className="text-xl" /></span>
                <span className="font-semibold ">Win History</span>
              </span>

              {/* 
              <span onClick={() => onClickMenu('/app/deposite_amount')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/deposite_amount" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><FaRegMoneyBillAlt  className="text-xl" /> <i class="fa-solid fa-money-bill-transfer"></i></span>
                
                <span className="font-semibold ">Deposit Amount</span>
              </span> */}


              {/* <span onClick={() => onClickMenu('/app/withdraw')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/withdraw" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><GiTakeMyMoney  className="text-xl" /> <i class="fa-solid fa-money-bill-transfer"></i></span>
                
                <span className="font-semibold ">Withdraw Amount</span>
              </span> */}


              <span onClick={() => onClickMenu('/app/wallet_history')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/wallet_history" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><RiPagesLine className="text-xl" /></span>
                <span className="font-semibold ">Transaction History</span>
              </span>


              {/* <span onClick={() => onClickMenu('/app/transfer_points')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/transfer_points" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><BiTransfer className="text-xl" /></span>
                <span className="font-semibold ">Transfer Points</span>
              </span> */}
              <span onClick={() => onClickMenu('/app/ChangePassword')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/ChangePassword" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><MdPassword className="text-xl" /></span>
                <span className="font-semibold ">Change Password</span>
              </span>
              {/* <span onClick={() => onClickMenu('/app/chart')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/chart" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><FaChartLine className="text-xl" /></span>
                <span className="font-semibold ">Chart</span>
              </span> */}

              <span onClick={() => onClickMenu('/app/gameRate')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/gameRate" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><FaStar className="text-xl" /></span>
                <span className="font-semibold ">Game Rate</span>
              </span>
            


              {/* <span onClick={() => onClickMenu('/app/adminBank')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/adminBank" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><BsBank2 className="text-xl" /></span>
                <span className="font-semibold ">Bank Deposit Details</span>
              </span> */}
              <span onClick={() => onClickMenu('/app/ledger')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/ledger" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><TbReportAnalytics className="text-xl" /></span>
                <span className="font-semibold ">My Statement</span>
              </span>

              <span onClick={() => onClickMenu('/app/howtoPlay')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/howtoPlay" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><TiInfoLarge className="text-xl" /></span>
                <span className="font-semibold ">How To Play</span>
              </span>

              <span onClick={() => handleLogout()} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/Logout" ? "text-white bg-[#f97941] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#f97941]"><RiLogoutBoxLine className="text-xl" /></span>
                <span className="font-semibold ">Logout</span>
              </span>
         
            </div>

          </div>
        </div>
      </div>
      <div onClick={() => setopensizebar(prev => !prev)} className="  flex-1"></div>
    </div>
  );
}

function mapStateToProps(state) {
  const { users } = state;
  const { user } = state.authentication;
  return {
    users,
    user,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
