import React from 'react';

export default function RulesNotification(props) {
    const { handleCloseNotification, DomainName } = props
    console.log("DomainNameDomainName", DomainName);
    return (
        <div className='h-full md:h-screen absolute top-0 left-0 z-50 w-full bg-black/30 pt-14 p-3'>
            <div className="bg-white md:w-[700px] w-full mx-auto">
                <div className="flex justify-between items-center bg-[#2A2A2A] text-center px-3 py-4 cursor-pointer w-full">
                    <span className="text-lg font-semibold text-white">Rules</span>
                    <span onClick={() => handleCloseNotification()} className="text-[#8F1820] font-medium hover:text-red-600 cursor-pointer text-3xl">&#10060;</span>
                </div>
                <div className='pl-6 py-3 w-full'>
                    <ol className='list-decimal text-sm space-y-3 w-full'>
                        <li> कृपया {DomainName} के नियमों को समझने के लिए यहां कुछ मिनट दें, और अपने अनुसार समझ लें |</li>
                        <li> सभी डीलर्स से निवेदन है कि क्लाइंट्स को {DomainName} के रूल्स समझाने के बाद ही सौदे करवायें।</li>
                        <li>अगर आप इस एग्रीमेंट को ऐक्सेप्ट नहीं करते हे तो कोई सौदा नहीं कीजिये।</li>
                        <li> इंटरनेट कनेक्शन प्रॉब्लम की जिम्मेवारी आपकी रहेगी</li>
                        <li> यहाँ सभी सौदे लेजर से मान्य किये जायेंगे</li>
                        <li> मैच के टाई और रद्द होने पे सारी कंप्लीटेड फैंसी का लेन देन किया जायेगा</li>
                        <li> साइट मैं किसी भी टेक्निकल Error आने की सूरत मैं मैच और सेशन मैं लगी सभी बेट्स का लेन देन होगा</li>
                        <li>अगर किसी भी मैच मैं भाव और फैंसी मैं गलत रेट चलने की सूरत मैं कंपनी अपने राइट्स से बेट्स डिलीट कर सकता है ऐसी सूरत मैं कोई भी वाद विवाद मान्य नहीं होगा</li>
                        <li>किसी मैच मैं रिजल्ट गलत हो जाने की सूरत मैं मैच खत्म हो जाने के बाद भी उसे सही किया जा सकता है इस सिचुएशन मैं कोई भी वाद विवाद। मान्य नहीं होगा</li>
                        <li>अगर एक्सचेंज मैं कोई भी चीट बेट्स पाई जाती है तो बिना किसी वाद विवाद के उसे कंपनी की तरफ से डिलीट कर दिया जायेगा</li>
                        <li>एक्सचेंज का स्कोरबोर्ड थर्ड पार्टी से लिया गया है उसे देख कर सौदे करने पर एक्सचेंज जिम्मेदार नहीं होगा</li>
                        <li>एक्सचेंज का स्कोरबोर्ड थर्ड पार्टी से लिया गया है उसे देख कर सौदे करने पर एक्सचेंज जिम्मेदार नहीं होगा</li>
                        <li>कंपनी द्वारा दिया गया पासवर्ड आपको खुद चेंज करना है ये आपकी अपनी जिम्मेदारी है इसके लिए कंपनी से कोई भी वाद विवाद मान्य नहीं होगा</li>
                        <li>अगर चलते मैच मैं किसी भी टेक्निकल इश्यू की वजह से एक्सचेंज डाउन होता है तो उस मैच और सेशन पर लगी हुई सभी बेट्स का लेन देन होगा इस सिचुएशन मैं कोई वाद विवाद मान्य नहीं होगा</li>
                    </ol>
                </div>
                <div className="flex justify-end items-center space-x-3 py-4 px-3 w-full border-t border-gray-300">
                    <span onClick={() => handleCloseNotification()} className="bg-[#2A2A2A] text-white px-3 py-2 rounded cursor-pointer">Agree</span>
                    <span onClick={() => handleCloseNotification()} className="bg-[#6C757D] text-white px-3 py-2 rounded cursor-pointer">Cancel</span>
                </div>
            </div>
        </div>
    );
}

