import React from 'react';
import { connect } from 'react-redux';
import { DomainName } from '../../_config/index';
import { alertActions, userActions } from '../../_actions';
import moment from 'moment-timezone';
import RulesNotification from "../../components/RulesNotification/RulesNotification";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPause } from "react-icons/fa";
import BetCloseAlert from '../../components/CloseBetAlert/CloseBetAlert';
import ChartLogo from "./rising.png"
import { FaPlay } from "react-icons/fa";
// import { FaWhatsapp } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { IoMdAddCircleOutline } from "react-icons/io";
import Loader from '../../components/Loader/Loader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SimpleSlider from '../../components/Sidebar/DemoCarousel';

class DashboardMainPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      notification: false,
      editStake: false,
      timeDifference: null,
      isPastClosingTime: false
    }
  }

  componentDidMount() {
    // const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    // if (!hasSeenPopup) {
    //   this.setState({ notification: true });
    //   localStorage.setItem('hasSeenPopup', true);
    // }
    let data =
    {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 100
    }

    this.props.dispatch(userActions.getAllSlider());
    this.props.dispatch(userActions.getAllAppSetting());
    this.props.dispatch(userActions.getAllBrandForAdmin(data));
    this.props.dispatch(userActions.getProfile())
    this.intervalId = setInterval(() => {
      this.props.dispatch(userActions.getGamesList(data));

    }, 2000);
  }

  componentWillUnmount() {

    clearInterval(this.intervalId);
  }


  navigateGameDetailsPage = (data) => {
    const closingTimeInMillis = data.closingTime;
    const currentTimeInMillis = new Date().getTime();

    if (currentTimeInMillis < closingTimeInMillis) {
      this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)
    } else {
      // this.setState({ isPastClosingTime: true });
      this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)
    }
  }

  handleCloseAlert = () => {
    this.setState({ isPastClosingTime: false });
  };

  handleCloseNotification = () => {
    this.setState({ notification: false });
    localStorage.setItem('hasSeenPopup', true);
  };

  render() {
    const { activeTab, notification, isPastClosingTime } = this.state;
    let { users } = this.props;
    let { totalGame, loading, gameList, brandData
      , currentTimeApi, appSettingData, slideData, getProfile } = users;
    let { blockedGames } = getProfile ? getProfile : {}




    function formatSecondsToTime(totalSeconds) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      const timeString = moment().startOf('day')
        .add(hours, 'hours')
        .add(minutes, 'minutes')
        .add(seconds, 'seconds')
        .format('hh:mm A');

      return timeString;
    }


    const convertSecondsToTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    };





    const getCurrentTime = () => {
      const now = moment();
      return now.format('hh:mm:A');
    };
    const currentTimeApis = getCurrentTime(currentTimeApi)


    let recentList = [{
      name: "Sridevi",
      closingTime: 211331313131,
      openTime: 1131313131312
    }]

    var settings = {
      button: false,
      dots: true,
      slickPrev: false,
      slickNext: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    console.log("blockedGames", blockedGames)
    console.log("getProfile", getProfile)
    let blockedGamesIds = blockedGames&&blockedGames.length>0&&blockedGames.map(e=>e?.games)?.filter(games=>games!==undefined)?.flat(Infinity)
    console.log(blockedGamesIds)

    return (

      <>
        {/* <div className='w-full ' style={{ backgroundImage: "url(/images/bg-all.jpg)" }}> */}
        <div className='w-full ' style={{ backgroundColor: "#fff" }}>
          <Loader loading={loading} />
          <div className=' flex space-x-1'>
            <div className="lg:flex w-full bg-white  space-x-1">
              <div className="w-full space-y-1 h-[96vh] overflow-y-auto pb-20">

                <div className=' overflow-hidden  w-full'>
                  <marquee class="text-black font-bold mt-1  bg-[#f97941] text-sm py-2 bgheader">{appSettingData && appSettingData.length > 0 && appSettingData.filter((e) => e.key === "MobileNumber")[0]?.value} </marquee>
                  <div className=' bg-[#3e44d5]'>
                    <SimpleSlider slideData={slideData} />
                  </div>

                  <div className='space-y-5 lg:space-y-10  pt-5 px-4'>

                    <Link to={"/app/BrandsList"} className=' w-full border-4 flex md:pr-12  shadow-orange-500 shadow-md justify-center items-center rounded-lg border-[#F97941] bg-[#3E44D5] text-[#F97941]  my-3 p-4 text-2xl  uppercase '>
                      <span className=' '>

                      </span>
                      <span style={{ fontFamily: "Algerian" }} className=' flex-1 text-center md:text-[50px] text-lg font-bold'>
                        FataFat Games
                      </span>
                    </Link>

                    {gameList
                      && gameList
                        .length > 0 ? gameList
                          .map((element, index) => {



                            const openingTime = moment()
                              .hours(parseInt(element && element.openTime / 3600))
                              .minutes(parseInt((element && element.openTime % 3600) / 60))
                              .seconds(parseInt(element && element.openTime % 60))
                              .milliseconds(0);

                            const closingTime = moment()
                              .hours(parseInt(element && element.closingTime / 3600))
                              .minutes(parseInt((element && element.closingTime % 3600) / 60))
                              .seconds(parseInt(element && element.closingTime % 60))
                              .milliseconds(0);

                            // --------------------------------------------------------
                            const closeCloseBidTime = moment.duration(element?.betClosingTime, 'seconds').asMinutes()
                            const timebeforeClose = closingTime.clone().subtract(closeCloseBidTime, 'minutes');
                            const timebeforeCloseBets = timebeforeClose.format("hh:mm A");
                            // --------------------------------------------------------
                            // --------------------------------------------------------
                            const closeOpenBidTimes = moment.duration(element?.betOpenTime, 'seconds').asMinutes()
                            const timebeforeOpen = openingTime.clone().subtract(closeOpenBidTimes, 'minutes');
                            const timebeforeOpenBets = timebeforeOpen.format("hh:mm A");
                            // -------------------------------------------------------- 

                            const OpenBetTime = openingTime.format("hh:mm A");
                            const CloseBetTime = closingTime.format("hh:mm A");
                            // isBefore close
                            const isBeforeClose = moment(currentTimeApis, "hh:mm A").isBefore(moment(timebeforeCloseBets, "hh:mm A"));
                       
                            if (!element.brandGame && !Array.isArray(blockedGamesIds)) {
                              // if(element)

                              return (
                                <div className='w-full  shadow-md  border-2 text-[#F97941] rounded-3xl bg-[#3E44D5] '>



                                  <div>

                                    <div className=' flex justify-between items-center  p-3'>
                                      <div className='flex  h-10 justify-center items-center flex-col'>
                                        <div className='text-[9px] md:text-base mt-2 font-bold'>Chart</div>

                                        <Link to={`/app/charts/${element?._id}/${element?.name}`}>
                                          <img src={ChartLogo} className=' w-14 h-14' />
                                        </Link>
                                        <div className='text-[9px] md:text-base mt-2 font-bold'>Open {OpenBetTime && OpenBetTime || ''}</div>
                                      </div>
                                      <div onClick={() => this.navigateGameDetailsPage(element)} className=' flex h-10 flex-col justify-center items-center'>
                                        <div className='uppercase text-base text-center  font-bold  lg:text-xl'>{element && element.name ? element.name : ""}</div>
                                        <div className='text-lg  py-4   font-semibold'>{element && element?.market && element?.market?.openNumber?.toString()=="0"?"000":element?.market?.openNumber?.toString() || "***"}-{element && element?.market && element?.market?.jodiNumber && element?.market?.jodiNumber.split("")[0] || "*"}{element && element?.market && element?.market?.jodiNumber?.toString() && element?.market?.jodiNumber.split("")[1] || "*"}-{element && element?.market && element?.market?.closeNumber?.toString()=="0"?"000":element?.market?.closeNumber?.toString() || "***"}</div>
                                        {isBeforeClose && !element?.market?.closeNumber?.toString() ? <div className='w-full text-[#38EF7D]  uppercase font-bold text-center'>Market Open</div>
                                          : <div className='w-full text-[#FE0000] font-bold  uppercase  text-center'>Market Close</div>
                                        }
                                      </div>


                                      <div onClick={() => this.navigateGameDetailsPage(element)} className=' flex justify-center flex-col gap-3 items-center'>
                                        <div className='uppercase text-base  font-bold  lg:text-xl'>{" "} </div>

                                        <div className={`flex justify-center rounded-full  text-white ${isBeforeClose && !element?.market?.closeNumber?.toString() ? 'bg-[#198f51]' : 'bg-[#F97941]'} w-12 h-12  items-center`}>
                                          {isBeforeClose && !element?.market?.closeNumber?.toString() ? <FaPlay className=' text-xl cursor-pointer' />
                                            : <FaPause className=' text-xl ' />}
                                        </div>
                                        <div className='text-[9px] md:text-base mt-2 font-bold  items-end '>Close {CloseBetTime && CloseBetTime || ''}
                                        </div>

                                      </div>

                                    </div>

                                  </div>



                                </div>
                              )
                            }else if (!element.brandGame && Array.isArray(blockedGamesIds)&&!blockedGamesIds?.includes(element._id)) {
                              // if(element)

                              return (
                                <div className='w-full  shadow-md  border-2 text-[#F97941] rounded-3xl bg-[#3E44D5] '>



                                  <div>

                                    <div className=' flex justify-between items-center  p-3'>
                                      <div className='flex  h-10 justify-center items-center flex-col'>
                                        <div className='text-[9px] md:text-base mt-2 font-bold'>Chart</div>

                                        <Link to={`/app/charts/${element?._id}/${element?.name}`}>
                                          <img src={ChartLogo} className=' w-14 h-14' />
                                        </Link>
                                        <div className='text-[9px] md:text-base mt-2 font-bold'>Open {OpenBetTime && OpenBetTime || ''}</div>
                                      </div>
                                      <div onClick={() => this.navigateGameDetailsPage(element)} className=' flex h-10 flex-col justify-center items-center'>
                                        <div className='uppercase text-base text-center  font-bold  lg:text-xl'>{element && element.name ? element.name : ""}</div>
                                        <div className='text-lg  py-4   font-semibold'>{element && element?.market && element?.market?.openNumber?.toString() || "***"}-{element && element?.market && element?.market?.jodiNumber && element?.market?.jodiNumber.split("")[0] || "*"}{element && element?.market && element?.market?.jodiNumber && element?.market?.jodiNumber.split("")[1] || "*"}-{element && element?.market && element?.market?.closeNumber.toString()=="0"?"000":element?.market?.closeNumber.toString() || "***"}</div>
                                        {isBeforeClose && !element?.market?.closeNumber?.toString() ? <div className='w-full text-[#38EF7D]  uppercase font-bold text-center'>Market Open</div>
                                          : <div className='w-full text-[#FE0000] font-bold  uppercase  text-center'>Market Close</div>
                                        }
                                      </div>


                                      <div onClick={() => this.navigateGameDetailsPage(element)} className=' flex justify-center flex-col gap-3 items-center'>
                                        <div className='uppercase text-base  font-bold  lg:text-xl'>{" "} </div>

                                        <div className={`flex justify-center rounded-full  text-white ${isBeforeClose && !element?.market?.closeNumber?.toString() ? 'bg-[#198f51]' : 'bg-[#F97941]'} w-12 h-12  items-center`}>
                                          {isBeforeClose && !element?.market?.closeNumber?.toString() ? <FaPlay className=' text-xl cursor-pointer' />
                                            : <FaPause className=' text-xl ' />}
                                        </div>
                                        <div className='text-[9px] md:text-base mt-2 font-bold  items-end '>Close {CloseBetTime && CloseBetTime || ''}
                                        </div>

                                      </div>

                                    </div>

                                  </div>



                                </div>
                              )
                            }

                          }) : null}
                  </div>

                </div>
              </div>
            </div>
          </div>
          {this.state.isPastClosingTime ?
            <BetCloseAlert
              handleCloseAlert={this.handleCloseAlert} /> : null}

          {notification &&
            <RulesNotification
              handleCloseNotification={this.handleCloseNotification}
              DomainName={DomainName}
            />}
        </div>
        {/* <Footer props={this.props} /> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(DashboardMainPage);
