export const betConstants = {


GETALL_ENTRIES_BET_REQUEST:"GETALL_ENTRIES_BET_REQUEST",
GETALL_ENTRIES_BET_SUCCESS:"GETALL_ENTRIES_BET_SUCCESS",
GETALL_ENTRIES_BET_FAILURE:"GETALL_ENTRIES_BET_FAILURE",

    GET_WINNING_LIST__REQUEST:"GET_WINNING_LIST__REQUEST",
    GET_WINNING_LIST__SUCCESS:"GET_WINNING_LIST__SUCCESS",
    GET_WINNING_LIST__FAILURE:"GET_WINNING_LIST__FAILURE",
    TRANSACTION_REQUEST:"TRANSACTION_REQUEST",
    TRANSACTION_SUCCESS:"TRANSACTION_SUCCESS",
    TRANSACTION_FAILURE:"TRANSACTION_FAILURE",

    BET_HISTORY_REQUEST: 'BET_HISTORY_REQUEST',
    BET_HISTORY_SUCCESS: 'BET_HISTORY_SUCCESS',
    BET_HISTORY_FAILURE: 'BET_HISTORY_FAILURE',

    UNSATTLED_BET_REQUEST: 'UNSATTLED_BET_REQUEST',
    UNSATTLED_BET_SUCCESS: 'UNSATTLED_BET_SUCCESS',
    UNSATTLED_BET_FAILURE: 'UNSATTLED_BET_FAILURE',

    CURRENT_BETS_REQUEST: 'CURRENT_BETS_REQUEST',
    CURRENT_BETS_SUCCESS: 'CURRENT_BETS_SUCCESS',
    CURRENT_BETS_FAILURE: 'CURRENT_BETS_FAILURE',

    BET_DEPOSIT_WITHDRAW_REQUEST: 'BET_DEPOSIT_WITHDRAW_REQUEST',
    BET_DEPOSIT_WITHDRAW_SUCCESS: 'BET_DEPOSIT_WITHDRAW_SUCCESS',
    BET_DEPOSIT_WITHDRAW_FAILURE: 'BET_DEPOSIT_WITHDRAW_FAILURE',

    GETALL_BET_REQUEST: 'GETALL_BET_REQUEST',
    GETALL_BET_SUCCESS: 'GETALL_BET_SUCCESS',
    GETALL_BET_FAILURE: 'GETALL_BET_FAILURE',

    GET_LIST_BET_REQUEST: 'GET_LIST_BET_REQUEST',
    GET_LIST_BET_SUCCESS: 'GET_LIST_BET_SUCCESS',
    GET_LIST_BET_FAILURE: 'GET_LIST_BET_FAILURE',

    ADD_BET_REQUEST: 'ADD_BET_REQUEST',
    ADD_BET_SUCCESS: 'ADD_BET_SUCCESS',
    ADD_BET_FAILURE: 'ADD_BET_FAILURE',

    DELETE_BET_REQUEST: 'DELETE_BET_REQUEST',
    DELETE_BET_SUCCESS: 'DELETE_BET_SUCCESS',
    DELETE_BET_FAILURE: 'DELETE_BET_FAILURE',

    UPDATE_BET_REQUEST: 'UPDATE_BET_REQUEST',
    UPDATE_BET_SUCCESS: 'UPDATE_BET_SUCCESS',
    UPDATE_BET_FAILURE: 'UPDATE_BET_FAILURE',

    DISABLE_BET_REQUEST: 'DISABLE_BET_REQUEST',
    DISABLE_BET_SUCCESS: 'DISABLE_BET_SUCCESS',
    DISABLE_BET_FAILURE: 'DISABLE_BET_FAILURE',

    GET_BET_BY_ID_REQUEST: 'GET_BET_BY_ID_REQUEST',
    GET_BET_BY_ID_SUCCESS: 'GET_BET_BY_ID_SUCCESS',
    GET_BET_BY_ID_FAILURE: 'GET_BET_BY_ID_FAILURE',

};
