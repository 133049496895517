import { alertConstants } from '../_constants';//Raman
import { toast } from 'react-toastify';


export const alertActions = {
    success,
    error,
    clear,
    warn,
};

function success(message,submessage) {
    let messagetest = message?.toString();
    let messagetest2 = submessage?.toString();
    toast.success(<div>
          <h2 className="text-lg font-bold">{messagetest}</h2>
      <p className="text-sm">{messagetest2}</p>
    </div>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    return { type: alertConstants.SUCCESS, message };
}

function error(message,submessage) {
    let messagetest = message?.toString();
    let messagetest2 = submessage?.toString();
    toast.error(<div>
        <h2 className="text-lg font-bold">{messagetest}</h2>
    <p className="text-sm">{messagetest2}</p>
  </div>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    return { type: alertConstants.ERROR, message: messagetest };
}
function warn(message,submessage) {
    let messagetest = message?.toString();
    let messagetest2 = submessage?.toString();
    toast.warn(<div>
        <h2 className="text-lg font-bold">{messagetest}</h2>
    <p className="text-sm">{messagetest2}</p>
  </div>, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    return { type: alertConstants.ERROR, message: messagetest };
}

function clear() {
    return { type: alertConstants.CLEAR };
}