import React from 'react'
import { useSelector } from 'react-redux'

export default function UpdateModel({userOpenModal,handleFile,handleHideModal,fieldsUser,errorsUser,inputChange,createUserSubmit,refUpdate}) {
    console.log(fieldsUser)
    let selector = useSelector(state=>state)
    let {users} =selector
    let {imgUrl}=users?users:{}
    console.log(imgUrl)
  return (
    <div className={userOpenModal?"fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster":'hidden' }style={{ background: 'rgba(0,0,0,.7)' }}>
    <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border  rounded shadow-lg modal-container md:max-w-md">
      <div className="px-6 py-4 text-left modal-content">
        {/*Title*/}
        <div className="flex items-center justify-between bg-[#3e44d5] px-1 rounded-lg py-2 text-white">
          <p className="text-2xl font-bold">Update User</p>
          <div className="z-50 cursor-pointer modal-close">
            <svg onClick={() => handleHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>
        {/*Body*/}
        <form autoComplete="off">
          <div className="{otpSent?'disableArea':''}">
            <div className="relative mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2" for="username">User Name :</label>
              <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["userName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                id="userName" name="userName" placeholder="UserName"
                // value={fieldsUser.userName}
                value={fieldsUser && fieldsUser["userName"] ? fieldsUser["userName"] : ""}

                type="text" onChange={inputChange} />
              {errorsUser && errorsUser["userName"] ?
                <div className="text-red-600 invalid-feedback">
                  {errorsUser["userName"]}
                </div>
                : null}
            </div>
          </div>

          <div >
            <div className="relative mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2" for="username">Mobile Number :</label>
              <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["mobNo"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                id="mobNo" name="mobNo" placeholder="mobNo" value={fieldsUser && fieldsUser["mobNo"] ? fieldsUser["mobNo"] : ""} type="text" onChange={inputChange} />
              {errorsUser && errorsUser["mobNo"] ?
                <div className="text-red-600 invalid-feedback">
                  {errorsUser["mobNo"]}
                </div>
                : null}
            </div>
          </div>

          {/* <div >
            <div className="relative mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2" for="username">email :</label>
              <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["value"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                id="email" name="email" placeholder="email"  value={fieldsUser && fieldsUser["email"] ? fieldsUser["email"] : ""}  type="text" onChange={inputChange} />
              {errorsUser && errorsUser["email"] ?
                <div className="text-red-600 invalid-feedback">
                  {errorsUser["email"]}
                </div>
                : null}
            </div>
          </div> */}

{/* {imgUrl&&imgUrl?      
<img className=' h-12 w-12 rounded-lg p-1 ' src={imgUrl||"/images/profile.png"}/>
:
<img className=' h-12 w-12 rounded-lg p-1 ' src={fieldsUser?.image||"/images/profile.png"}/>

}
          <div className="flex flex-wrap w-full mt-2">
            <label htmlFor="image">Upload Image</label>
            <input
           ref={refUpdate}
              id="image"
              name="image"
              type="file"
              onChange={handleFile}

            />
          </div> */}


          <div className="w-64 mx-auto mt-4 sm:w-72">
            <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-[#3e44d5] border border-[#3e44d5] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#3e44d5] focus:shadow-outline-yellow active:bg-[#3e44d5]" type="button" onClick={createUserSubmit}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>



  )
}
