import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { userActions } from '../../_actions';
import Sidebar from "../../components/Sidebar/Sidebar";
import Loader from '../../components/Loader/Loader';


class PartyWinLoss extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      fieldsMatch: {},
      errorsMatch: {},
      currentCount: 0,
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().format('YYYY-MM-DD'),
    }
  }

  componentDidMount() {
    let data =
    {
      "from_date": moment(this.state.startDate).startOf('day').unix(),
      "to_date": moment(this.state.endDate).endOf('day').unix(),
      "limit": 15,
      "pageno": 1,
      "betType": "P",
      "match_id": 0,
      "sport_id": 0,
    }
    console.log("getBatHistorydatedateeee>>>>>>>", data);
    this.props.dispatch(userActions.getProfitAndLoss(data));
  }

  getStementSubmit = () => {
    let data = {
      "from_date": moment(this.state.startDate).startOf('day').unix(),
      "to_date": moment(this.state.endDate).endOf('day').unix(),
      "limit": 15,
      "pageno": 1,
      "betType": "p",
      "match_id": "0",
      "sport_id": "0",
    }

    console.log("changePassword>>>>>>>", data);
    this.props.dispatch(userActions.getProfitAndLoss(data));
  }

  inputChangeStartDate = (e) => {
    console.log('e_______________________', e.target.value);
    console.log('starttttttttttttttttttt', e.target.name, e.target.value);
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    // e.preventDefault();
    console.log('endddddddddddddddddddddddddd', e.target.name, e.target.value);
    this.setState({ endDate: e.target.value });
  }

  setCurrentCount = (data) => {
    this.setState({ currentCount: data });
  };

  render() {
    let { users } = this.props;
    let { profit_and_loss, loading } = users;

    console.log("profit_and_lossprofit_and_losssssssssssssssssssss", profit_and_loss);

    return (
      <>
        <div>
          <Loader
            active={loading}
          />
        </div>
        <div className='relative w-full h-full bg-white overflow-y-auto  flex'>
          <div>
            <Sidebar
              open={true}
              // setOpen={setOpen}
              // setOpenMobile={setOpenMobile}
              // openMobile={openMobile}
              showSport={true}
              props={this.props}
            />
          </div>
          <div className='w-full px-0.5 space-y-4'>
            <div className='flex justify-between bg-[#1C77B7] p-1.5' >
              <h2 className='text-[0.8125rem] font-medium text-white'>Profit & Loss Report</h2>
            </div>
            <div className='flex space-x-4'>
              <input type="date" className="px-3 py-1.5 text-[0.8125rem] bg-transparent border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 "
                name="Date"
                dateFormat="yyyy-mm-dd"
                id="datepicker" required
                value={this.state.startDate} onChange={this.inputChangeStartDate} />

              <input type="date" className="px-3 py-1.5 text-[0.8125rem] bg-transparent border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 "
                name="Date"
                dateFormat="yyyy-mm-dd"
                id="datepicker" required
                value={this.state.endDate} onChange={this.inputChangeEndDate}
              />
              <button onClick={() => this.getStementSubmit()} className='py-1.5 px-4 bg-[#1B7BFF] text-white text-[0.8125rem] rounded'>Submit</button>
            </div>
            <div className="overflow-hidden ">
              <div className="max-w-full overflow-auto ">
                <div className="inline-block min-w-full ">
                  <div className="overflow-hidden w-full ">
                    <table className="min-w-full capitalize border border-collapse ">
                      <tr className="grid grid-cols-10 text-white text-[0.8125rem] text-left font-semibold bg-[#265467]">
                        <th className="px-[6px] py-1 whitespace-nowrap border border-slate-300">Sport</th>
                        <th className="px-[6px] py-1 whitespace-nowrap border border-slate-300 col-span-6">Match</th>
                        <th className="px-[6px] py-1 whitespace-nowrap border border-slate-300 col-span-3">Amount</th>
                      </tr>
                      {
                        profit_and_loss && profit_and_loss.length > 0 ?
                          profit_and_loss.map((element, index) => (
                            <React.Fragment >
                              <tr className="grid grid-cols-10 text-black text-[0.8125rem] text-left font-medium">
                                <td className="px-[6px] py-1 whitespace-nowrap border border-slate-300">{element && element.sportName ? element.sportName : "-"}</td>
                                <td className="px-[6px] py-1 whitespace-nowrap border border-slate-300 col-span-6">{element && element.matchName ? element.matchName : "-"}</td>
                                <td className={`px-[6px] py-1 whitespace-nowrap border border-slate-300 col-span-3 ${element.userPL > 0 ? "text-green-600" : "text-red-600"}`}>{element && element.userPL ? element.userPL : "-"}</td>
                              </tr>

                            </React.Fragment>
                          )) :
                          <tr className="grid grid-cols-10">
                            <td className="col-span-10 py-1 px-[6px] text-black text-sm text-center whitespace-nowrap">
                              There are no records to show
                            </td>
                          </tr>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;

  console.log("users:::ACTIVE_MATCH::", users);

  return {
    users
  };
}

export default connect(mapStateToProps)(PartyWinLoss);

