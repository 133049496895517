export const userConstants = {
    ADMIN_BANK_DETAILS_REQUEST:"ADMIN_BANK_DETAILS_REQUEST",
    ADMIN_BANK_DETAILS_SUCCESS:"ADMIN_BANK_DETAILS_SUCCESS",
    ADMIN_BANK_DETAILS_FAILURE:"ADMIN_BANK_DETAILS_FAILURE",
    CHART_REQUEST:"CHART_REQUEST",
    CHART_SUCCESS:"CHART_SUCCESS",
    CHART_FAILURE:"CHART_FAILURE",
    CLEAR:"CLEAR",

    TODAY_BETS_REQUEST:"TODAY_BETS_REQUEST",
    TODAY_BETS_SUCCESS:"TODAY_BETS_SUCCESS",
    TODAY_BETS_FAILURE:"TODAY_BETS_FAILURE",



    CALANDER_KOLKATA_FATAFAT_REQUEST:"CALANDER_KOLKATA_FATAFAT_REQUEST",
    CALANDER_KOLKATA_FATAFAT_SUCCESS:"CALANDER_KOLKATA_FATAFAT_SUCCESS",
    CALANDER_KOLKATA_FATAFAT_FAILURE:"CALANDER_KOLKATA_FATAFAT_FAILURE",

    GAME_RATE_REQUEST:"GAME_RATE_REQUEST",
    GAME_RATE_SUCCESS:"GAME_RATE_SUCCESS",
    GAME_RATE_FAILURE:"GAME_RATE_FAILURE",


    REGISTER_OTP_REQUEST:"REGISTER_OTP_REQUEST",
    REGISTER_OTP_SUCCESS:"REGISTER_OTP_SUCCESS",
    REGISTER_OTP_FAILURE:"REGISTER_OTP_FAILURE",


    GET_BRAND_GAME_ID_FROM_ADMIN_REQUEST:"GET_BRAND_GAME_ID_FROM_ADMIN_REQUEST",
    GET_BRAND_GAME_ID_FROM_ADMIN_SUCCESS:"GET_BRAND_GAME_ID_FROM_ADMIN_SUCCESS",
    GET_BRAND_GAME_ID_FROM_ADMIN_FAILURE:"GET_BRAND_GAME_ID_FROM_ADMIN_FAILURE",

    GET_ALL_BRAND_FOR_ADMIN_REQUEST:"GET_ALL_BRAND_FOR_ADMIN_REQUEST",
    GET_ALL_BRAND_FOR_ADMIN_SUCCESS:"GET_ALL_BRAND_FOR_ADMIN_SUCCESS",
    GET_ALL_BRAND_FOR_ADMIN_FAILURE:"GET_ALL_BRAND_FOR_ADMIN_FAILURE",


REGISTER_USER_REQUEST:"REGISTER_USER_REQUEST",
REGISTER_USER_SUCCESS:"REGISTER_USER_SUCCESS",
REGISTER_USER_FAILURE:"REGISTER_USER_FAILURE",
    CLEAR_USER:"CLEAR_USER",

    FORGOT_PASSWORD_VALID_OTP_REQUEST:"FORGOT_PASSWORD_VALID_OTP_REQUEST",
    FORGOT_PASSWORD_VALID_OTP_SUCCESS:"FORGOT_PASSWORD_VALID_OTP_SUCCESS",
    FORGOT_PASSWORD_VALID_OTP_FAILURE:"FORGOT_PASSWORD_VALID_OTP_FAILURE",

    FORGOT_PASSWORD_REQUEST:"FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS:"FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILURE:"FORGOT_PASSWORD_FAILURE",

    UPDATE_USER_REQUEST:"UPDATE_USER_REQUEST",
    UPDATE_USER_SUCCESS:"UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE:"UPDATE_USER_FAILURE",

    TRANSFERPOINTS_REQUEST:"TRANSFERPOINTS_REQUEST",
    TRANSFERPOINTS_SUCCESS:"TRANSFERPOINTS_SUCCESS",
    TRANSFERPOINTS_FAILURE:"TRANSFERPOINTS_FAILURE",

    GET_GAME_BY_GAMEID_REQUEST:"GET_GAME_BY_GAMEID_REQUEST",
    GET_GAME_BY_GAMEID_SUCCESS:"GET_GAME_BY_GAMEID_SUCCESS",
    GET_GAME_BY_GAMEID_FAILURE:"GET_GAME_BY_GAMEID_FAILURE",


    SAVE_CLIENT_PAYMENT_REQUEST:"SAVE_CLIENT_PAYMENT_REQUEST",
    SAVE_CLIENT_PAYMENT_SUCCESS:"SAVE_CLIENT_PAYMENT_SUCCESS",
    SAVE_CLIENT_PAYMENT_FAILURE:"SAVE_CLIENT_PAYMENT_FAILURE",


    UPLOAD_IMAGE__REQUEST:"UPLOAD_IMAGE__REQUEST",
    UPLOAD_IMAGE__SUCCESS:"UPLOAD_IMAGE__SUCCESS",
    UPLOAD_IMAGE__FAILURE:"UPLOAD_IMAGE__FAILURE",
    CLEAR_IMAGE:"CLEAR_IMAGE",

    GET_BANK_REQUEST:"GET_BANK_REQUEST",
    GET_BANK_SUCCESS:"GET_BANK_SUCCESS",
    GET_BANK_FAILURE:"GET_BANK_FAILURE",


    GET_ALL_APP_SETING_REQUEST:"GET_ALL_APP_SETING_REQUEST",
    GET_ALL_APP_SETING_SUCCESS:"GET_ALL_APP_SETING_SUCCESS",
    GET_ALL_APP_SETING_FAILURE:"GET_ALL_APP_SETING_FAILURE",

    GET_PROFILE_USER_REQUEST:"GET_PROFILE_USER_REQUEST",
    GET_PROFILE_USER_SUCCESS:"GET_PROFILE_USER_SUCCESS",
    GET_PROFILE_USER_FAILURE:"GET_PROFILE_USER_FAILURE",


    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GET_ALL_SLIDER_REQUEST:"GET_ALL_SLIDER_REQUEST",
    GET_ALL_SLIDER_SUCCESS:"GET_ALL_SLIDER_SUCCESS",
    GET_ALL_SLIDER_FAILURE:"GET_ALL_SLIDER_FAILURE",

    ACTIVE_SPORT_REQUEST: 'ACTIVE_SPORT_REQUEST',
    ACTIVE_SPORT_SUCCESS: 'ACTIVE_SPORT_SUCCESS',
    ACTIVE_SPORT_FAILURE: 'ACTIVE_SPORT_FAILURE',

    MARKET_ANALYSIS_DASHBORD_REQUEST: 'MARKET_ANALYSIS_DASHBORD_REQUEST',
    MARKET_ANALYSIS_DASHBORD_SUCCESS: 'MARKET_ANALYSIS_DASHBORD_SUCCESS',
    MARKET_ANALYSIS_DASHBORD_FAILURE: 'MARKET_ANALYSIS_DASHBORD_FAILURE',

    WALLET_BALANCE_REQUEST: 'WALLET_BALANCE_REQUEST',
    WALLET_BALANCE_SUCCESS: 'WALLET_BALANCE_SUCCESS',
    WALLET_BALANCE_FAILURE: 'WALLET_BALANCE_FAILURE',

    CHILD_LIST_ACTIVE_USER_REQUEST: 'CHILD_LIST_ACTIVE_USER_REQUEST',
    CHILD_LIST_ACTIVE_USER_SUCCESS: 'CHILD_LIST_ACTIVE_USER_SUCCESS',
    CHILD_LIST_ACTIVE_USER_FAILURE: 'CHILD_LIST_ACTIVE_USER_FAILURE',

    USER_ACCOUNT_DETAILS_REQUEST: 'USER_ACCOUNT_DETAILS_REQUEST',
    USER_ACCOUNT_DETAILS_SUCCESS: 'USER_ACCOUNT_DETAILS_SUCCESS',
    USER_ACCOUNT_DETAILS_FAILURE: 'USER_ACCOUNT_DETAILS_FAILURE',

    GET_MARKET_BY_MARKETID_REQUEST: 'GET_MARKET_BY_MARKETID_REQUEST',
    GET_MARKET_BY_MARKETID_SUCCESS: 'GET_MARKET_BY_MARKETID_SUCCESS',
    GET_MARKET_BY_MARKETID_FAILURE: 'GET_MARKET_BY_MARKETID_FAILURE',

    GAME_PROFILE_LIST_REQUEST: 'GAME_PROFILE_LIST_REQUEST',
    GAME_PROFILE_LIST_SUCCESS: 'GAME_PROFILE_LIST_SUCCESS',
    GAME_PROFILE_LIST_FAILURE: 'GAME_PROFILE_LIST_FAILURE',

    GAME_LIST_REQUEST: 'GAME_LIST_REQUEST',
    GAME_LIST_SUCCESS: 'GAME_LIST_SUCCESS',
    GAME_LIST_FAILURE: 'GAME_LIST_FAILURE',

    GET_BET_LIST_REQUEST: 'GET_BET_LIST_REQUEST',
    GET_BET_LIST_SUCCESS: 'GET_BET_LIST_SUCCESS',
    GET_BET_LIST_FAILURE: 'GET_BET_LIST_FAILURE',

    GET_TRANSCTION_LIST_REQUEST: 'GET_TRANSCTION_LIST_REQUEST',
    GET_TRANSCTION_LIST_SUCCESS: 'GET_TRANSCTION_LIST_SUCCESS',
    GET_TRANSCTION_LIST_FAILURE: 'GET_TRANSCTION_LIST_FAILURE',

    MARKET_LIST_REQUEST: 'MARKET_LIST_REQUEST',
    MARKET_LIST_SUCCESS: 'MARKET_LIST_SUCCESS',
    MARKET_LIST_FAILURE: 'MARKET_LIST_FAILURE',

    MATCH_LIST_REQUEST: 'MATCH_LIST_REQUEST',
    MATCH_LIST_SUCCESS: 'MATCH_LIST_SUCCESS',
    MATCH_LIST_FAILURE: 'MATCH_LIST_FAILURE',

    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

    PLACE_BET_REQUEST: 'PLACE_BET_REQUEST',
    PLACE_BET_SUCCESS: 'PLACE_BET_SUCCESS',
    PLACE_BET_FAILURE: 'PLACE_BET_FAILURE',

    GET_SCORE_REQUEST: 'GET_SCORE_REQUEST',
    GET_SCORE_SUCCESS: 'GET_SCORE_SUCCESS',
    GET_SCORE_FAILURE: 'GET_SCORE_FAILURE',

    GET_MARKET_REQUEST: 'GET_MARKET_REQUEST',
    GET_MARKET_SUCCESS: 'GET_MARKET_SUCCESS',
    GET_MARKET_FAILURE: 'GET_MARKET_FAILURE',

    GET_MARKET_CASINO_REQUEST: 'GET_MARKET_CASINO_REQUEST',
    GET_MARKET_CASINO_SUCCESS: 'GET_MARKET_CASINO_SUCCESS',
    GET_MARKET_CASINO_FAILURE: 'GET_MARKET_CASINO_FAILURE',

    STATEMENT_REQUEST: 'STATEMENT_REQUEST',
    STATEMENT_SUCCESS: 'STATEMENT_SUCCESS',
    STATEMENT_FAILURE: 'STATEMENT_FAILURE',

    EVENT_GAME_LIST_REQUEST: 'EVENT_GAME_LIST_REQUEST',
    EVENT_GAME_LIST_SUCCESS: 'EVENT_GAME_LIST_SUCCESS',
    EVENT_GAME_LIST_FAILURE: 'EVENT_GAME_LIST_FAILURE',

    EVENT_GAME_REQUEST: 'EVENT_GAME_REQUEST',
    EVENT_GAME_SUCCESS: 'EVENT_GAME_SUCCESS',
    EVENT_GAME_FAILURE: 'EVENT_GAME_FAILURE',

    EVENT_FUN_CSNO_REQUEST: 'EVENT_FUN_CSNO_REQUEST',
    EVENT_FUN_CSNO_SUCCESS: 'EVENT_FUN_CSNO_SUCCESS',
    EVENT_FUN_CSNO_FAILURE: 'EVENT_FUN_CSNO_FAILURE',

    EVENT_CASINO_REQUEST: 'EVENT_CASINO_REQUEST',
    EVENT_CASINO_SUCCESS: 'EVENT_CASINO_SUCCESS',
    EVENT_CASINO_FAILURE: 'EVENT_CASINO_FAILURE',

    LAST_RESULT_REQUEST: 'LAST_RESULT_REQUEST',
    LAST_RESULT_SUCCESS: 'LAST_RESULT_SUCCESS',
    LAST_RESULT_FAILURE: 'LAST_RESULT_FAILURE',

    EVENT_FOOTBAL_REQUEST: 'EVENT_FOOTBAL_REQUEST',
    EVENT_FOOTBAL_SUCCESS: 'EVENT_FOOTBAL_SUCCESS',
    EVENT_FOOTBAL_FAILURE: 'EVENT_FOOTBAL_FAILURE',

    EVENT_TENNIS_REQUEST: 'EVENT_TENNIS_REQUEST',
    EVENT_TENNIS_SUCCESS: 'EVENT_TENNIS_SUCCESS',
    EVENT_TENNIS_FAILURE: 'EVENT_TENNIS_FAILURE',

    LIST_FN_MATCH_REQUEST: 'LIST_FN_MATCH_REQUEST',
    LIST_FN_MATCH_SUCCESS: 'LIST_FN_MATCH_SUCCESS',
    LIST_FN_MATCH_FAILURE: 'LIST_FN_MATCH_FAILURE',

    SAVE_CSN_BET_REQUEST: 'SAVE_CSN_BET_REQUEST',
    SAVE_CSN_BET_SUCCESS: 'SAVE_CSN_BET_SUCCESS',
    SAVE_CSN_BET_FAILURE: 'SAVE_CSN_BET_FAILURE',

    LIST_BT_SSN_REQUEST: 'LIST_BT_SSN_REQUEST',
    LIST_BT_SSN_SUCCESS: 'LIST_BT_SSN_SUCCESS',
    LIST_BT_SSN_FAILURE: 'LIST_BT_SSN_FAILURE',

    GET_MATCH_SCORE_REQUEST: 'GET_MATCH_SCORE_REQUEST',
    GET_MATCH_SCORE_SUCCESS: 'GET_MATCH_SCORE_SUCCESS',
    GET_MATCH_SCORE_FAILURE: 'GET_MATCH_SCORE_FAILURE',

    SINGLE_CLICK_UPDATE_REQUEST: 'SINGLE_CLICK_UPDATE_REQUEST',
    SINGLE_CLICK_UPDATE_SUCCESS: 'SINGLE_CLICK_UPDATE_SUCCESS',
    SINGLE_CLICK_UPDATE_FAILURE: 'SINGLE_CLICK_UPDATE_FAILURE',

    EVENT_DETALS_REQUEST: 'EVENT_DETALS_REQUEST',
    EVENT_DETALS_SUCCESS: 'EVENT_DETALS_SUCCESS',
    EVENT_DETALS_FAILURE: 'EVENT_DETALS_FAILURE',

    EVENT_SESSION_REQUEST: 'EVENT_SESSION_REQUEST',
    EVENT_SESSION_SUCCESS: 'EVENT_SESSION_SUCCESS',
    EVENT_SESSION_FAILURE: 'EVENT_SESSION_FAILURE',































    UPDATE_MATCH_FIM_ID_REQUEST: 'UPDATE_MATCH_FIM_ID_REQUEST',
    UPDATE_MATCH_FIM_ID_SUCCESS: 'UPDATE_MATCH_FIM_ID_SUCCESS',
    UPDATE_MATCH_FIM_ID_FAILURE: 'UPDATE_MATCH_FIM_ID_FAILURE',

    LIVE_MATCHES_REQUEST: 'LIVE_MATCHES_REQUEST',
    LIVE_MATCHES_SUCCESS: 'LIVE_MATCHES_SUCCESS',
    LIVE_MATCHES_FAILURE: 'LIVE_MATCHES_FAILURE',

    UPCOMING_MATCHES_REQUEST: 'UPCOMING_MATCHES_REQUEST',
    UPCOMING_MATCHES_SUCCESS: 'UPCOMING_MATCHES_SUCCESS',
    UPCOMING_MATCHES_FAILURE: 'UPCOMING_MATCHES_FAILURE',

    DISABLE_APP_SETTING_REQUEST: 'DISABLE_APP_SETTING_REQUEST',
    DISABLE_APP_SETTING_SUCCESS: 'DISABLE_APP_SETTING_SUCCESS',
    DISABLE_APP_SETTING_FAILURE: 'DISABLE_APP_SETTING_FAILURE',

    CREATE_APP_SETTING_REQUEST: 'CREATE_APP_SETTING_REQUEST',
    CREATE_APP_SETTING_SUCCESS: 'CREATE_APP_SETTING_SUCCESS',
    CREATE_APP_SETTING_FAILURE: 'CREATE_APP_SETTING_FAILURE',

    UPDATE_APP_SETTING_REQUEST: 'UPDATE_APP_SETTING_REQUEST',
    UPDATE_APP_SETTING_SUCCESS: 'UPDATE_APP_SETTING_SUCCESS',
    UPDATE_APP_SETTING_FAILURE: 'UPDATE_APP_SETTING_FAILURE',

    GET_APP_SETTING_LIST_REQUEST: 'GET_APP_SETTING_LIST_REQUEST',
    GET_APP_SETTING_LIST_SUCCESS: 'GET_APP_SETTING_LIST_SUCCESS',
    GET_APP_SETTING_LIST_FAILURE: 'GET_APP_SETTING_LIST_FAILURE',

    DELETE_APP_SETTING_REQUEST: 'DELETE_APP_SETTING_REQUEST',
    DELETE_APP_SETTING_SUCCESS: 'DELETE_APP_SETTING_SUCCESS',
    DELETE_APP_SETTING_FAILURE: 'DELETE_APP_SETTING_FAILURE',

    LOGIN_FIRST_REQUEST: 'USERS_FIRST_LOGIN_REQUEST',
    LOGIN_FIRST_SUCCESS: 'USERS_FIRST_LOGIN_SUCCESS',
    LOGIN_FIRST_FAILURE: 'USERS_FIRST_LOGIN_FAILURE',

    DECLARE_RESULT_REQUEST: 'DECLARE_RESULT_REQUEST',
    DECLARE_RESULT_SUCCESS: 'DECLARE_RESULT_SUCCESS',
    DECLARE_RESULT_FAILURE: 'DECLARE_RESULT_FAILURE',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    GET_PL_REQUEST: 'GET_PL_REQUEST',
    GET_PL_SUCCESS: 'GET_PL_SUCCESS',
    GET_PL_FAILURE: 'GET_PL_FAILURE',

    WITHDRAW_REQUEST: 'WITHDRAW_REQUEST',
    WITHDRAW_SUCCESS: 'WITHDRAW_SUCCESS',
    WITHDRAW_FAILURE: 'WITHDRAW_FAILURE',

    SAVE_BET_REQUEST: 'SAVE_BET_REQUEST',
    SAVE_BET_SUCCESS: 'SAVE_BET_SUCCESS',
    SAVE_BET_FAILURE: 'SAVE_BET_FAILURE',

    SAVE_SSN_BET_REQUEST: 'SAVE_SSN_BET_REQUEST',
    SAVE_SSN_BET_SUCCESS: 'SAVE_SSN_BET_SUCCESS',
    SAVE_SSN_BET_FAILURE: 'SAVE_SSN_BET_FAILURE',

    GET_USER_SETTING_REQUEST: 'GET_USER_SETTING_REQUEST',
    GET_USER_SETTING_SUCCESS: 'GET_USER_SETTING_SUCCESS',
    GET_USER_SETTING_FAILURE: 'GET_USER_SETTING_FAILURE',


    GET_MATCH_BY_MATCH_ID_V2_REQUEST: 'GET_MATCH_BY_MATCH_ID_V2_REQUEST',
    GET_MATCH_BY_MATCH_ID_V2_SUCCESS: 'GET_MATCH_BY_MATCH_ID_V2_SUCCESS',
    GET_MATCH_BY_MATCH_ID_V2_FAILURE: 'GET_MATCH_BY_MATCH_ID_V2_FAILURE',

    GET_USER_LIST_BY_USER_TYPE_REQUEST: 'GET_USER_LIST_BY_USER_TYPE_REQUEST',
    GET_USER_LIST_BY_USER_TYPE_SUCCESS: 'GET_USER_LIST_BY_USER_TYPE_SUCCESS',
    GET_USER_LIST_BY_USER_TYPE_FAILURE: 'GET_USER_LIST_BY_USER_TYPE_FAILURE',

    GET_WORLD_FANCY_REQUEST: 'GET_WORLD_FANCY_REQUEST',
    GET_WORLD_FANCY_SUCCESS: 'GET_WORLD_FANCY_SUCCESS',
    GET_WORLD_FANCY_FAILURE: 'GET_WORLD_FANCY_FAILURE',

    GET_SELECTION_BY_MARKET_ID_REQUEST: 'GET_SELECTION_BY_MARKET_ID_REQUEST',
    GET_SELECTION_BY_MARKET_ID_SUCCESS: 'GET_SELECTION_BY_MARKET_ID_SUCCESS',
    GET_SELECTION_BY_MARKET_ID_FAILURE: 'GET_SELECTION_BY_MARKET_ID_FAILURE',

    DEPOSIT_REQUEST: 'DEPOSIT_REQUEST',
    DEPOSIT_SUCCESS: 'DEPOSIT_SUCCESS',
    DEPOSIT_FAILURE: 'DEPOSIT_FAILURE',

    PROFIT_AND_LOSS_REQUEST: 'PROFIT_AND_LOSS_REQUEST',
    PROFIT_AND_LOSS_SUCCESS: 'PROFIT_AND_LOSS_SUCCESS',
    PROFIT_AND_LOSS_FAILURE: 'PROFIT_AND_LOSS_FAILURE',

    GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',

    GET_ALL_MATCH_ACTIVE_REQUEST: 'GET_ALL_MATCH_ACTIVE_REQUEST',
    GET_ALL_MATCH_ACTIVE_SUCCESS: 'GET_ALL_MATCH_ACTIVE_SUCCESS',
    GET_ALL_MATCH_ACTIVE_FAILURE: 'GET_ALL_MATCH_ACTIVE_FAILURE',

    GET_USER_DETAILS_BY_ID_REQUEST: 'GET_USER_DETAILS_BY_ID_REQUEST',
    GET_USER_DETAILS_BY_ID_SUCCESS: 'GET_USER_DETAILS_BY_ID_SUCCESS',
    GET_USER_DETAILS_BY_ID_FAILURE: 'GET_USER_DETAILS_BY_ID_FAILURE',

    GET_STATEMENT_REQUEST: 'GET_STATEMENT_REQUEST',
    GET_STATEMENT_SUCCESS: 'GET_STATEMENT_SUCCESS',
    GET_STATEMENT_FAILURE: 'GET_STATEMENT_FAILURE',

    GET_BETS_REQUEST: 'GET_BETS_REQUEST',
    GET_BETS_SUCCESS: 'GET_BETS_SUCCESS',
    GET_BETS_FAILURE: 'GET_BETS_FAILURE',

    GET_OWN_CHILD_REQUEST: 'GET_OWN_CHILD_REQUEST',
    GET_OWN_CHILD_SUCCESS: 'GET_OWN_CHILD_SUCCESS',
    GET_OWN_CHILD_FAILURE: 'GET_OWN_CHILD_FAILURE',

    SEND_REQUEST: 'USERS_SEND_REQUEST',
    SEND_SUCCESS: 'USERS_SEND_SUCCESS',
    SEND_FAILURE: 'USERS_SEND_FAILURE',

    GET_ALL_MATCH_BY_SPORT_ID_REQUEST: 'GET_ALL_MATCH_BY_SPORT_ID_REQUEST',
    GET_ALL_MATCH_BY_SPORT_ID_SUCCESS: 'GET_ALL_MATCH_BY_SPORT_ID_SUCCESS',
    GET_ALL_MATCH_BY_SPORT_ID_FAILURE: 'GET_ALL_MATCH_BY_SPORT_ID_FAILURE',

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

    CHANGE_OWN_PASSWORD_REQUEST: 'CHANGE_OWN_PASSWORD_REQUEST',
    CHANGE_OWN_PASSWORD_SUCCESS: 'CHANGE_OWN_PASSWORD_SUCCESS',
    CHANGE_OWN_PASSWORD_FAILURE: 'CHANGE_OWN_PASSWORD_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    SEND_OTP_REQUEST: 'USERS_SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS: 'USERS_SEND_OTP_SUCCESS',
    SEND_OTP_FAILURE: 'USERS_SEND_OTP_FAILURE',

    TOKEN_REQUEST: 'USERS_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'USERS_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'USERS_TOKEN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
    USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
    USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',

    USER_TX_REQUEST: 'USER_TX_REQUEST',
    USER_TX_SUCCESS: 'USER_TX_SUCCESS',
    USER_TX_FAILURE: 'USER_TX_FAILURE',

    GETALL_USER_NOTIFY_REQUEST: 'GETALL_USER_NOTIFY_REQUEST',
    GETALL_USER_NOTIFY_SUCCESS: 'GETALL_USER_NOTIFY_SUCCESS',
    GETALL_USER_NOTIFY_FAILURE: 'GETALL_USER_NOTIFY_FAILURE',

    UPDATE_USER_NOTIFY_REQUEST: 'UPDATE_USER_NOTIFY_REQUEST',
    UPDATE_USER_NOTIFY_SUCCESS: 'UPDATE_USER_NOTIFY_SUCCESS',
    UPDATE_USER_NOTIFY_FAILURE: 'UPDATE_USER_NOTIFY_FAILURE',


    ADD_RESTAURANT_USER_REQUEST: 'ADD_RESTAURANT_USER_REQUEST',
    ADD_RESTAURANT_USER_SUCCESS: 'ADD_RESTAURANT_USER_SUCCESS',
    ADD_RESTAURANT_USER_FAILURE: 'ADD_RESTAURANT_USER_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

    ADMIN_CODE: "AD",
    SUPER_STOCKIST_CODE: "SSKT",
    STOCKIST_CODE: "SKT",
    AGENT_CODE: "AGT",
    USER_CODE: "USER",

    GETALL_SPORT_REQUEST: 'GETALL_SPORT_REQUEST',
    GETALL_SPORT_SUCCESS: 'GETALL_SPORT_SUCCESS',
    GETALL_SPORT_FAILURE: 'GETALL_SPORT_FAILURE',

    ADD_SERIES_REQUEST: 'ADD_SERIES_REQUEST',
    ADD_SERIES_SUCCESS: 'ADD_SERIES_SUCCESS',
    ADD_SERIES_FAILURE: 'ADD_SERIES_FAILURE',

    GETALL_SERIES_REQUEST: 'GETALL_SERIES_REQUEST',
    GETALL_SERIES_SUCCESS: 'GETALL_SERIES_SUCCESS',
    GETALL_SERIES_FAILURE: 'GETALL_SERIES_FAILURE',

    GETALL_MATCH_REQUEST: 'GETALL_MATCH_REQUEST',
    GETALL_MATCH_SUCCESS: 'GETALL_MATCH_SUCCESS',
    GETALL_MATCH_FAILURE: 'GETALL_MATCH_FAILURE',

    GETALL_MARKET_REQUEST: 'GETALL_MARKET_REQUEST',
    GETALL_MARKET_SUCCESS: 'GETALL_MARKET_SUCCESS',
    GETALL_MARKET_FAILURE: 'GETALL_MARKET_FAILURE',

    GET_ALL_FANCY_REQUEST: 'GET_ALL_FANCY_REQUEST',
    GET_ALL_FANCY_SUCCESS: 'GET_ALL_FANCY_SUCCESS',
    GET_ALL_FANCY_FAILURE: 'GET_ALL_FANCY_FAILURE',

    ADD_MATCH_REQUEST: 'ADD_MATCH_REQUEST',
    ADD_MATCH_SUCCESS: 'ADD_MATCH_SUCCESS',
    ADD_MATCH_FAILURE: 'ADD_MATCH_FAILURE',

    CREATE_MARKET_REQUEST: 'CREATE_MARKET_REQUEST',
    CREATE_MARKET_SUCCESS: 'CREATE_MARKET_SUCCESS',
    CREATE_MARKET_FAILURE: 'CREATE_MARKET_FAILURE',

    UPDATE_FANCY_STATUS_REQUEST: 'UPDATE_FANCY_STATUS_REQUEST',
    UPDATE_FANCY_STATUS_SUCCESS: 'UPDATE_FANCY_STATUS_SUCCESS',
    UPDATE_FANCY_STATUS_FAILURE: 'UPDATE_FANCY_STATUS_FAILURE',

    UPDATE_MATCH_STATUS_REQUEST: 'UPDATE_MATCH_STATUS_REQUEST',
    UPDATE_MATCH_STATUS_SUCCESS: 'UPDATE_MATCH_STATUS_SUCCESS',
    UPDATE_MATCH_STATUS_FAILURE: 'UPDATE_MATCH_STATUS_FAILURE',

    GET_MY_SHARE_REQUEST: 'GET_MY_SHARE_REQUEST',
    GET_MY_SHARE_SUCCESS: 'GET_MY_SHARE_SUCCESS',
    GET_MY_SHARE_FAILURE: 'GET_MY_SHARE_FAILURE',

    GET_ALL_MATCH_BY_SPORTID_REQUEST: 'GET_ALL_MATCH_BY_SPORTID_REQUEST',
    GET_ALL_MATCH_BY_SPORTID_SUCCESS: 'GET_ALL_MATCH_BY_SPORTID_SUCCESS',
    GET_ALL_MATCH_BY_SPORTID_FAILURE: 'GET_ALL_MATCH_BY_SPORTID_FAILURE',

    GET_ALL_FANCY_BY_MATCH_ID_REQUEST: 'GET_ALL_FANCY_BY_MATCH_ID_REQUEST',
    GET_ALL_FANCY_BY_MATCH_ID_SUCCESS: 'GET_ALL_FANCY_BY_MATCH_ID_SUCCESS',
    GET_ALL_FANCY_BY_MATCH_ID_FAILURE: 'GET_ALL_FANCY_BY_MATCH_ID_FAILURE',

    GET_BETS_BY_MATCH_ID_REQUEST: 'GET_BETS_BY_MATCH_ID_REQUEST',
    GET_BETS_BY_MATCH_ID_SUCCESS: 'GET_BETS_BY_MATCH_ID_SUCCESS',
    GET_BETS_BY_MATCH_ID_FAILURE: 'GET_BETS_BY_MATCH_ID_FAILURE',

    GET_ALL_MATCH_SESSION_REQUEST: 'GET_ALL_MATCH_SESSION_REQUEST',
    GET_ALL_MATCH_SESSION_SUCCESS: 'GET_ALL_MATCH_SESSION_SUCCESS',
    GET_ALL_MATCH_SESSION_FAILURE: 'GET_ALL_MATCH_SESSION_FAILURE',

};
