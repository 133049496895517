import React, { useState } from 'react';
import { Route, Switch, withRouter, } from "react-router-dom";
import Header from "../Header";
import LiveGames from "../../pages/LiveGames/LiveGames";
import AccountStatement from "../../pages/AccountStatement/AccountStatement";
import UserHistory from "../../pages/UserHistory/UserHistory";
import BetHistory from "../../pages/BetHistory/BetHistory";
import ProfitAndLoss from "../../pages/ProfitAndLoss/ProfitAndLoss";
import Dashboard from "../../pages/Dashboard/Dashboard";
// import ChangePasswordModal from "./Component/ChangePassword";
import MatchOdds from '../../pages/MatchOdds/MatchOdds';
import Casino from '../../pages/Casino/casino';
import UnsatteledBet from '../../pages/UnsatteledBet/UnsatteledBet'
import changepassword from '../../pages/ChangePassword/changepassword';
import cricketdetails from '../../pages/CricketDetails/cricketdetails';
import matches from '../../pages/Matches/matches';
import MyLedger from '../../pages/MyLedger/MyLedger';
import SattaEvents from '../../pages/SattaEvents/SattaEvents';
// import Footer from '../../components/Footer';npm
import SattaEventId from '../../pages/SattaEventId/SattaEventId';
import Events from '../../pages/Events/Events';
import gameDetails from '../../pages/gameDetails/gameDetails';
import MatkaDetails from '../../pages/MatkaDetails/MatkaDetails';
import MatkaSingle from '../../pages/MatkaSingle/MatkaSingle';
import MatkaDoublePatti from '../../pages/MatkaDoublePatti/MatkaDoublePatti';
import MatkaSinglePatti from '../../pages/MatkaSinglePatti/MatkaSinglePatti';
import MatkaTriplePatti from '../../pages/MatkaTriplePatti/MatkaTriplePatti';
import MatkaJodi from '../../pages/MatkaJodi/MatkaJodi';
import MatkaOddEven from '../../pages/MatkaOddEven/MatkaOddEven';
import Ledger from '../../pages/Ledger/Ledger';
// import WalletHistory from '../../pages/WalletHistory/WalletHistory';
import Sidebar from '../Sidebar/Sidebar';
import UserBidHistory from '../../pages/BetHistory/BetHistory';
import Profile from '../../pages/Profile/Profile';
import WinHistory from '../../pages/WinHistory/WinHistory';
import DepositeAmount from '../../pages/DepositeAmount/DepositeAmount';
import WithdrawAmount from '../../pages/WithdrawAmount/WithdrawAmount';
import TransferPoints from '../../pages/TransferPoints/TransferPoints';
import TransictionHistory from '../../pages/TransictionHistory/TransictionHistory';
import AdminDetails from '../../pages/Profile/AdminDetails';
import HowToPlay from '../../pages/HowToPlay/HowToPlay';
import Chart from '../../pages/Chart/Chart';
import Chartrr from '../../pages/Chartrr/Chartrr';
import DashboardMainPage from '../../pages/DashboardMainPage/DashboardMainPage';
import KolkataSingle from '../../pages/KolkataSingle/KolkataSingle';
import KolkataMatkaSingle from '../../pages/KolkataMatkaSingle/KolkataMatkaSingle';
import KolkataFatafatChart from '../../pages/KolkataFatafatChart/KolkataFatafatChart';
import Rate from '../../pages/Rate/Rate';
import BrandsList from '../../pages/BrandsList/BrandsList';
import Ledgers from '../../pages/TransictionHistory/Ledgers';


function Layout(props) {
  const [open, setOpen] = useState(false);

  const [openMobile, setOpenMobile] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [opensizebar, setopensizebar] = useState(false);


  // console.log(opensizebar)

  return (
    <div className="overflow-hidden h-screen md:flex bg-black justify-center  "  >

      <div className="overflow-hidden lg:w-[50vw] w-full ">
        <Header history={props.history}
          setOpen={setOpen} open={open}
          setOpenMobile={setOpenMobile}
          openMobile={openMobile}
          openModal={openModal} setOpenModal={setOpenModal}
          setopensizebar={setopensizebar} opensizebar={opensizebar} 
        />
        <div className="md:flex h-screen relative z-20 justify-center overflow-hidden ">

          <div className={opensizebar?' absolute z-50  transition-all duration-300 ease-in-out top-0 left-0 w-full bg-black bg-opacity-80 ':'absolute  transition-all duration-300 ease-in-out z-50 top-0 left-0 w-0 bg-black bg-opacity-80'}>

            <Sidebar setopensizebar={setopensizebar} opensizebar={opensizebar} />
          </div>
          <div className="lg:w-[50vw] w-full overflow-y-auto h-full">
            <Switch>
              <Route path="/app/ac-statement" component={withRouter(AccountStatement)} />
              <Route path="/app/userhistory" component={withRouter(UserHistory)} />
              <Route path="/app/my-bets" component={withRouter(UserBidHistory)} />
              <Route path="/app/matka_ledger" component={withRouter(Ledger)} />
              <Route path="/app/wallet_history" component={withRouter(TransictionHistory)} />
              <Route path="/app/ledger" component={withRouter(Ledgers)} />
              <Route path="/app/unsetteled-bet" component={withRouter(UnsatteledBet)} />
              <Route path="/app/profit-loss" component={withRouter(ProfitAndLoss)} />
              <Route path="/app/dashboard/:brandId" component={withRouter(Dashboard)} />
              <Route path="/app/dashboard" component={withRouter(DashboardMainPage)} />
              <Route path="/app/BrandsList" component={withRouter(BrandsList)} />
              <Route path="/app/match" component={withRouter(MatchOdds)} />
              <Route path="/app/casino" component={withRouter(Casino)} />
              <Route path="/app/win_history" component={withRouter(WinHistory)} />
              <Route path="/app/my_profile" component={withRouter(Profile)} />
              <Route path="/app/adminBank" component={withRouter(AdminDetails)} />
              <Route path="/app/changepassword" component={withRouter(changepassword)} />
              <Route path="/app/howtoPlay" component={withRouter(HowToPlay)} />
              <Route path="/app/chart" component={withRouter(Chart)} />
              <Route path="/app/gameRate" component={withRouter(Rate)} />
              <Route path="/app/charts/:gameId/:gameName" component={withRouter(Chartrr)} />
              <Route path="/app/livegames" component={withRouter(LiveGames)} />
              <Route path="/app/my-ledger" component={withRouter(MyLedger)} />
              <Route path="/app/panel_chart" component={withRouter(SattaEvents)} />
              <Route path="/app/deposite_amount" component={withRouter(DepositeAmount)} />
              <Route path="/app/withdraw" component={withRouter(WithdrawAmount)} />
              <Route path="/app/transfer_points" component={withRouter(TransferPoints)} />
              <Route path="/app/satta-events-id" component={withRouter(SattaEventId)} />
              <Route path="/app/events" component={withRouter(Events)} />
              <Route path="/app/matches/:sportId?/:seriesId?" component={withRouter(matches)} />
              <Route path="/app/cricket-details/:sportId?/:seriesId?/:match_id?/:marketId?" component={withRouter(cricketdetails)} />
              <Route path="/app/gameDetails/:market_id" component={withRouter(gameDetails)} />
              <Route path="/app/matkaDetails/:gameName/:gameId" component={withRouter(MatkaDetails)} />





              <Route path="/app/matkaSingle/:gameName/:gameId/:marketId" component={withRouter(MatkaSingle)} />
              <Route path="/app/matkaJodi/:gameName/:gameId/:marketId" component={withRouter(MatkaJodi)} />
              {/* <Route path="/app/matkaOddEven/:gameName/:gameId/:marketId" component={withRouter(MatkaOddEven)} /> */}
              <Route path="/app/matkaSinglePatti/:gameName/:gameId/:marketId" component={withRouter(MatkaSinglePatti)} />
              <Route path="/app/matkaDoublePatti/:gameName/:gameId/:marketId" component={withRouter(MatkaDoublePatti)} />
              <Route path="/app/matkaTriplePatti/:gameName/:gameId/:marketId" component={withRouter(MatkaTriplePatti)} />


              {/* ------------Kolkata--------- */}
              <Route path="/app/kolkataSingle/:gameName/:gameId/:marketId" component={withRouter(KolkataSingle)} />
              <Route path="/app/fatafat/:BrandName/:id" component={withRouter(KolkataFatafatChart)} />
              <Route path="/app/kolkataSinglePanna/:gameName/:gameId/:marketId" component={withRouter(KolkataMatkaSingle)} />
              
            </Switch>
          </div>
        </div>
        {/* <Footer props={props} /> */}
      </div>

    </div>
  );
}

export default withRouter(Layout);
