import React, { useEffect, useRef, useState } from 'react'
import { FaUserCircle } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import { userActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import UpdateModel from './Component/UpdateModel';
import Loader from '../../components/Loader/Loader'
import { IoCaretBackCircle } from "react-icons/io5";
import UpdateModelBank from './Component/UpdateModelBank';
export default function AdminDetails() {
    const dispatch = useDispatch();
    const selector = useSelector(state => state)
    const history = useHistory();

    const [userOpenModal, setuserOpenModal] = useState(false)
    const [userOpenModal2, setuserOpenModal2] = useState(false)
    const [bankShow, setbankShow] = useState(false);
    const [fieldsUser, setfieldsUser] = useState({});
    const [fieldsUser2, setfieldsUser2] = useState({});
    const [errorsUser, seterrorsUser] = useState({});
    const [errorsUser2, seterrorsUser2] = useState({});
    const refUpdate = useRef(null)
    let [userDetatils, setuserDetatils] = useState({})
    let [bankDetails, setbankDetails] = useState({})
    let [errorsBank, seterrorsBank] = useState({})
    let { users } = selector ? selector : {}
    let { getProfile, getBankdetails, loading,adminDetails } = users ? users : {}
    


    useEffect(() => {
        
        
        dispatch(userActions.getAdminBankDetailForUser())
   
    }, [])

    let handleClick1 = () => {
        setfieldsUser2(getBankdetails)
        if (refUpdate.current) {
            refUpdate.current.value = ""
        }
        setuserOpenModal2((e) => !e)
        dispatch(userActions.clearImage())
    }
    let handleClick = () => {
        setfieldsUser(getProfile)
        if (refUpdate.current) {
            refUpdate.current.value = ""
        }
        setuserOpenModal((e) => !e)
        dispatch(userActions.clearImage())
    }

    let handleHideModal = () => {
        if (refUpdate.current) {
            refUpdate.current.value = ""
        }
        setuserOpenModal((e) => !e)
        dispatch(userActions.clearImage())
        seterrorsUser({})

    }
    let handleHideModal2 = () => {
        if (refUpdate.current) {
            refUpdate.current.value = ""
        }
        setuserOpenModal2((e) => !e)
        dispatch(userActions.clearImage())
        seterrorsUser2({})

    }

    let inputChange = (e) => {
        setfieldsUser({ ...fieldsUser, [e.target.name]: e.target.value })
        seterrorsUser({ ...errorsUser, [e.target.name]: "" })
    }
    let inputChange2 = (e) => {
        setfieldsUser2({ ...fieldsUser2, [e.target.name]: e.target.value })
        seterrorsUser2({ ...errorsUser2, [e.target.name]: "" })
    }

    const handleFile = (event) => {

        if (event.target.files[0]) {
            dispatch(userActions.uploadImage(event))

        }
        else {
            console.log("No File To Upload!")
        }

    }

    const handleValidationCreateMarket = () => {
        let formIsValid = true;
        let errors = {};
        let { users } = selector
        let { imgUrl } = users ? users : {}


        if (!fieldsUser.userName || fieldsUser.userName.trim() === "") {
            formIsValid = false;
            errors.userName = "Empty Fields";
        }else if (fieldsUser.userName.includes(" ")){
            formIsValid = false;
            errors["userName"] = "Spaces are not allowed in the  Name.";
          }

        if (!fieldsUser.mobNo || fieldsUser.mobNo.trim() === "") {
            formIsValid = false;
            errors.mobNo = "Empty Mobile Fields";
        }


        seterrorsUser(errors);
        return formIsValid;
    };
    const createUserSubmit = () => {
        let { users } = selector
        let { imgUrl } = users ? users : {}
        console.log(imgUrl)
        if (handleValidationCreateMarket()) {
            let reqData = {
                "userName": fieldsUser?.userName.trim(),
                "mobNo": fieldsUser?.mobNo.trim(),
                "image": imgUrl || fieldsUser.image || ""

            }
            dispatch(userActions.updateUser(reqData))
            handleHideModal()

        }
    }

    const createUserSubmit2 = () => {
        let { users } = selector
        let { imgUrl } = users ? users : {}
        console.log(imgUrl)
        if (handleValidationCreateMarket2()) {
            let reqData = {
                "accountNo": fieldsUser2 && fieldsUser2.accountNo,
                "ifsc": fieldsUser2 && fieldsUser2.ifsc.trim(),
                "name": fieldsUser2 && fieldsUser2.name.trim(),
                "bankName": fieldsUser2 && fieldsUser2.bankName.trim(),
                "upiId": fieldsUser2 && fieldsUser2.upiId.trim()

            }
            dispatch(userActions.updateBankDetail(reqData))
            handleHideModal2()

        }



    }
    const handleValidationCreateMarket2 = () => {
        let formIsValid = true;
        let errors = {};
        let { users } = selector
        let { imgUrl } = users ? users : {}

        console.log( !fieldsUser2  )



        if (!fieldsUser2 || fieldsUser2.name.trim() === "") {
            formIsValid = false;
            errors.name = "Empty name Fields";
        }
     
        if (!fieldsUser2 || fieldsUser2.ifsc.trim() === "") {
            formIsValid = false;
            errors.ifsc = "Empty ifsc Fields";
        }

      

        if (!fieldsUser2|| fieldsUser2.accountNo === "") {
            formIsValid = false;
            errors.accountNo = "Empty Account number";
        }


        if (!fieldsUser2 || fieldsUser2.bankName.trim() === "") {
            formIsValid = false;
            errors.bankName = "Empty bankName Fields";
        }
        if (!fieldsUser2 || fieldsUser2.upiId.trim() === "") {
            formIsValid = false;
            errors.upiId = "Empty upiId Fields";
        }


        seterrorsUser2(errors);
        return formIsValid;
    };


    return (
        <div className='bg-white h-full'>
            <Loader loading={loading} />
            <IoCaretBackCircle className=' cursor-pointer text-3xl text-[#3e44d5]' onClick={()=>history.push('/app/dashboard')} />
    
            <div className='  overflow-y-scroll p-2'>

                <div className=' '>



<div className=' flex justify-center items-center md:text-3xl text-xl  font-bold mb-3 text-[#3e44d5]'>Admin Bank Details</div>

                    <form class="max-w-sm mx-auto">


                        <div class="mb-5">
                            <label class="block mb-2 text-sm font-medium text-gray-900 " for="name">Bank Name:</label>
                            <input className="w-full px-3 py-2   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none"
                                id="email" name="email"
                                value={adminDetails && adminDetails["bankName"] ? adminDetails["bankName"] : ""} type="text" disabled />
                            {errorsBank && errorsBank["bankName"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                    {errorsBank["bankName"]}
                                </div>
                                : null}
                        </div>

                        <div class="mb-5">
                            <label class="block mb-2 text-sm font-medium text-gray-900 " for="name">Bank Holder Name:</label>
                            <input className="w-full px-3 py-2   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none"
                                id="email" name="email"
                                value={adminDetails && adminDetails["name"] ? adminDetails["name"] : ""} type="text" disabled />
                            {errorsBank && errorsBank["email"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                    {errorsBank["email"]}
                                </div>
                                : null}
                        </div>
                        <div class="mb-5">
                            <label class="block mb-2 text-sm font-medium text-gray-900 " for="name">Account Number:</label>
                            <input className="w-full px-3 py-2   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none"
                                id="email" name="email"
                                value={adminDetails && adminDetails["accountNo"] ? adminDetails["accountNo"] : ""} type="number" disabled />
                            {errorsBank && errorsBank["email"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                    {errorsBank["email"]}
                                </div>
                                : null}
                        </div>

                        <div class="mb-5">
                            <label class="block mb-2 text-sm font-medium text-gray-900 " for="name">Ifsc code:</label>
                            <input className="w-full px-3 py-2   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none"
                                id="email" name="email"
                                value={adminDetails && adminDetails["ifsc"] ? adminDetails["ifsc"] : ""} type="text" disabled />
                            {errorsBank && errorsBank["ifsc"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                    {errorsBank["ifsc"]}
                                </div>
                                : null}
                        </div>

                        <div class="mb-16">
                            <label class="block mb-2 text-sm font-medium text-gray-900 " for="name">UPI Id:</label>
                            <input className="w-full px-3 py-2   bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none"
                                id="email" name="email"
                                value={adminDetails && adminDetails["upiId"] ? adminDetails["upiId"] : ""} type="text" disabled />
                            {errorsBank && errorsBank["upiId"] ?
                                <div className="text-yellow-600 invalid-feedback">
                                    {errorsBank["upiId"]}
                                </div>
                                : null}
                        </div>
                 






                    </form>

                    <UpdateModel
                        userOpenModal={userOpenModal}
                        handleHideModal={handleHideModal}
                        fieldsUser={fieldsUser}
                        errorsUser={errorsUser}
                        inputChange={inputChange}
                        createUserSubmit={createUserSubmit}
                        handleFile={handleFile}
                        refUpdate={refUpdate}
                    />
                    <UpdateModelBank
                        userOpenModal2={userOpenModal2}
                        handleHideModal2={handleHideModal2}
                        fieldsUser2={fieldsUser2}
                        errorsUser2={errorsUser2}
                        inputChange2={inputChange2}
                        createUserSubmit2={createUserSubmit2}


                    />


                </div>

            </div>
        </div>
    )
}
