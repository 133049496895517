import React from 'react';
import { connect } from 'react-redux';
import { betActions, userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Sidebar from "../../components/Sidebar/Sidebar";


class satteledBet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 10,
    }
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      // "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    this.props.dispatch(betActions.betHistory(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData =
    {
      // "keyWord": "",
      "pageNo": this.state.pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 200
    }

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }


  getBatHistory = () => {
    let data = {
      "fromDate": this.state.startDate,
      "toDate": this.state.endDate,
      "gameId": this.state.sportsId,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size,

    }

    this.props.dispatch(betActions.betHistory(data));
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };


  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        "fromDate": this.state.startDate,
        "toDate": this.state.endDate,
        // "keyWord": "",
        "gameId": this.state.sportsId,
        "sortBy": "",
        "sortOrder": "",
        "pageNo": this.state.pageNo,
        "size": this.state.size,
      }
      this.props.dispatch(betActions.betHistory(pagination));
    })

  }

  render() {

    let { bet, users } = this.props;
    let { betList, loading, betLoading, totalBet } = bet;
    let { totalGame, gameList } = users;
    const today = new Date().toISOString().split('T')[0];
    return (

      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className='bg-gray-200 w-full min-h-screen flex py-10'>
          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className='lg:w-[90%] w-full  space-y-4 mx-auto'>
            <div className=' text-xl font-bold'>Bid History</div>
            <div className='  border grid grid-cols-1 lg:grid-cols-7 divide-x  lg:divide-y-0'>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>From Date</label>
                <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.startDate} onChange={this.inputChangeStartDate} />
              </div>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>To Date</label>
                <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.endDate} onChange={this.inputChangeEndDate}
                />
              </div>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>Game Name</label>
                <select onChange={this.handleChangeSelect} name="sports_id" className=' w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none block'>
                  <option value="">All</option>
                  {gameList && gameList.length > 0 ? gameList.map((element, index) => (
                    <option value={element && element._id} className='capitalize '>{element && element.name}</option>
                  )) : null}
                </select>
              </div>
              <div className='col-span-1 flex flex-col justify-end items-end p-4'>
                <label className=''> </label>
                <button onClick={() => this.getBatHistory()} className='py-1.5 px-4 bg-[#3e44d5] text-white text-[0.8125rem] rounded'>Submit</button>
              </div>
            </div>
            <div className="overflow-hidden ">
              <div className="max-w-full overflow-auto ">
                {/* <div className="inline-block min-w-full "> */}
                  {/* <div className="overflow-hidden w-full "> */}

                    <div className='  flex flex-col  gap-5 border-2'>


                      {betList && betList.length > 0 && betList.map((element, index) => {

                        return (
                          <div className=' flex  w-full flex-col  text-xs border-gray-500 rounded-r-md border p-4'>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>S.No.</div>  {this.state.offset + index + 1}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Market</div>    {element && element.marketId && element.marketId.name ? element.marketId.name : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Game</div>{element && element.gameId && element.gameId.name ? element.gameId.name : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>NUMBER</div>{element && element.betNumber ? element.betNumber : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Point</div> {element && element.amount ? element.amount : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>BID TYPE</div>{element && element.isOpen ? "Open" : "Close"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Date</div>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A")}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Profit/Loss</div>  {element && element.betStatus == 0 && <div className=' text-yellow-500'>Pending</div>}
                              {element && element.betStatus == 1 && <div className=' text-green-800'>+{element?.winAmount}</div>}
                              {element && element.betStatus == 2 && <div className=' text-red-700'>-{element?.lossAmount}</div>}</div>
                          </div>
                        )
                      })}
                    </div>
                    {

                      <nav className="relative z-0 flex mb-10 justify-end  w-76">
                        {
                          totalBet && totalBet > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={totalBet / 10}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                    }

                  {/* </div> */}
                {/* </div> */}

              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet
  };
}

export default connect(mapStateToProps)(satteledBet);

