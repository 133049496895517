import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { IoBarChartSharp } from "react-icons/io5";
import Loader from '../../components/Loader/Loader';
const Chart = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { appSettingData, gameList,loading } = users;

  useEffect(() => {
    let data =
    {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 100
    }
    // dispatch(userActions.getAllAppSetting());
    dispatch(userActions.getGamesList(data));
  }, [])

  console.log(gameList)


  return (
    <>
      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='flex space-x-1 p-1 h-[90vh]  overflow-y-scroll'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <Loader loading={loading}/>
          <div className='w-full'>
            <IoCaretBackCircleSharp onClick={() => history.push('/')} className=' text-3xl text-[#3e44d5] cursor-pointer m-1' />

            <div className=' bg-white p-5 flex flex-col gap-4 rounded-md mb-10 '>
              {gameList && gameList.length > 0 && gameList.map((e) => {

                return (

                  <div onClick={() => history.push(`/app/charts/${e?._id}/${e?.name}`)} title='chart' className=' capitalize cursor-pointer bg-[#3e44d5] flex justify-between items-center px-3 py-2 rounded-lg font-bold text-white'>
                    <div>{e?.name}</div>
                    <div><IoBarChartSharp /></div>

                  </div>
                )

              })}

            </div>




          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(Chart));