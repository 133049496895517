import React from 'react'

export default function UpdateModelBank({userOpenModal2,handleHideModal2,fieldsUser2,errorsUser2,inputChange2,createUserSubmit2}) {
  console.log(fieldsUser2)

  return (
    <div className={userOpenModal2?"fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster":'hidden' }style={{ background: 'rgba(0,0,0,.7)' }}>
    <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border  rounded shadow-lg modal-container md:max-w-md">
      <div className="px-6 py-4 text-left modal-content">
        {/*Title*/}
        <div className="flex items-center justify-between bg-[#3e44d5] px-1 rounded-lg py-2 text-white">
          <p className="text-2xl font-bold">Update Bank Details</p>
          <div className="z-50 cursor-pointer modal-close">
            <svg onClick={() => handleHideModal2()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>
        {/*Body*/}
        <form autoComplete="off">
          <div className="{otpSent?'disableArea':''}">
            <div className="relative mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2" for="name">Bank Holder Name:</label>
              <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser2 && !errorsUser2["name"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                id="name" name="name" placeholder="Bank Holder Name"
                // value={fieldsUser2.name}
                value={fieldsUser2 && fieldsUser2["name"] ? fieldsUser2["name"] : ""}

                type="text" onChange={inputChange2} />
              {errorsUser2 && errorsUser2["name"] ?
                <div className="text-red-600 invalid-feedback">
                  {errorsUser2["name"]}
                </div>
                : null}
            </div>
          </div>
          <div className="{otpSent?'disableArea':''}">
            <div className="relative mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2" for="bankName">Bank Name:</label>
              <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser2 && !errorsUser2["bankName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                id="bankName" name="bankName" placeholder="Bank Name"
                // value={fieldsUser2.bankName}
                value={fieldsUser2 && fieldsUser2["bankName"] ? fieldsUser2["bankName"] : ""}

                type="text" onChange={inputChange2} />
              {errorsUser2 && errorsUser2["bankName"] ?
                <div className="text-red-600 invalid-feedback">
                  {errorsUser2["bankName"]}
                </div>
                : null}
            </div>
          </div>
          <div className="{otpSent?'disableArea':''}">
            <div className="relative mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2" for="ifsc">Ifsc Code:</label>
              <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser2 && !errorsUser2["ifsc"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                id="ifsc" name="ifsc" placeholder="Ifsc Code"
                // value={fieldsUser2.ifsc}
                value={fieldsUser2 && fieldsUser2["ifsc"] ? fieldsUser2["ifsc"] : ""}

                type="text" onChange={inputChange2} />
              {errorsUser2 && errorsUser2["ifsc"] ?
                <div className="text-red-600 invalid-feedback">
                  {errorsUser2["ifsc"]}
                </div>
                : null}
            </div>
          </div>
          <div className="{otpSent?'disableArea':''}">
            <div className="relative mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2" for="accountNo">Account Number:</label>
              <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser2 && !errorsUser2["accountNo"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                id="accountNo" name="accountNo" placeholder="Account Number"
                // value={fieldsUser2.accountNo}
                value={fieldsUser2 && fieldsUser2["accountNo"] ? fieldsUser2["accountNo"] : ""}

                type="number" onChange={inputChange2} />
              {errorsUser2 && errorsUser2["accountNo"] ?
                <div className="text-red-600 invalid-feedback">
                  {errorsUser2["accountNo"]}
                </div>
                : null}
            </div>
          </div>
          <div className="{otpSent?'disableArea':''}">
            <div className="relative mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2" for="upiId">Upi Id :</label>
              <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser2 && !errorsUser2["upiId"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                id="upiId" name="upiId" placeholder="Upi Id"
                // value={fieldsUser2.upiId}
                value={fieldsUser2 && fieldsUser2["upiId"] ? fieldsUser2["upiId"] : ""}

                type="text" onChange={inputChange2} />
              {errorsUser2 && errorsUser2["upiId"] ?
                <div className="text-red-600 invalid-feedback">
                  {errorsUser2["upiId"]}
                </div>
                : null}
            </div>
          </div>

       
  



          <div className="w-64 mx-auto mt-4 sm:w-72">
            <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-[#3e44d5] border border-[#3e44d5] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#3e44d5] focus:shadow-outline-yellow active:bg-[#3e44d5]" type="button" onClick={createUserSubmit2}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>



  )
}
