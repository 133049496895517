import React from 'react';
import moment from 'moment';
export default function ConfirmationModal(props) {
    let { isModelOpen, isModalOpenClose, arrayOfBets, walletAmount, letOpenModelClose, placeBet, type } = props;
    const totalAmount = arrayOfBets && arrayOfBets.length > 0
        ? arrayOfBets.reduce((total, bet) => total + parseInt(bet.amount), 0)
        : 0;

    const currentDate = moment();
    const formattedDate = currentDate.format('DD-MMM-YYYY HH:mm');
    return (
        <>

            <div className={isModelOpen ? "fixed inset-0 bg-black  bg-opacity-50 flex items-center justify-center" : "hidden"} onClick={isModalOpenClose}>
                <div className="bg-white  rounded-lg border-[#3e44d5] border-4 shadow-lg md:max-w-md max-w-[20rem] w-full" >
                    <h2 className='text-2xl font-bold  p-2 text-white    text-center bg-[#3e44d5]'>{formattedDate}</h2>
                    <div className='bg-white px-4 py-1'>
                        <div className=' flex justify-between px-2 bg-[#3e44d5] text-white p-1 rounded-md m-0.5'>
                            <h6 className='text-base font-bold '>Digit</h6>
                            <h6 className='text-base font-bold '>Amount</h6>
                            <h6 className='text-base font-bold '>Type</h6>
                        </div>
                        <div className=' border-b max-h-72 overflow-y-auto overflow-x-hidden p-0.5  border-black'>
                            {arrayOfBets && arrayOfBets.length > 0 && arrayOfBets.map((e) => {

                                return (
                                    <div className=' flex justify-between border border-[#3e44d5] px-2 m-0.5 rounded w-full'>
                                        <div>{e?.betNumber}</div>
                                        <div>{e?.amount}</div>
                                        <div>{type}</div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className=' flex justify-between '>
                            <div>Total Bids Amount </div>
                            <div>₹{totalAmount || ""}</div>
                        </div>
                        <div className=' flex justify-between '>
                            <div>Wallet Before Deduction </div>
                            <div>₹ {walletAmount || ""}</div>
                        </div>
                        <div className=' flex justify-between '>
                            <div>Wallet After Deduction </div>
                            <div>₹ {walletAmount - totalAmount || "0"}</div>
                        </div>
                        <div className=' flex w-full flex-col  border-t border-black pt-2 gap-1 mt-2'>
                            <p className=' md:text-sm text-xs text-red-700 '>*Note: Bid played once,cannot be cancelled</p>
                            <div className=' flex w-full gap-3 mt-1'>
                                <button onClick={letOpenModelClose} className=' bg-[#3e44d5] text-white  px-3 py-1 rounded-lg flex-1 uppercase'>Cancel</button>
                                <button onClick={placeBet} className=' bg-[#3e44d5] text-white  px-3 py-1 rounded-lg flex-1 uppercase' >Submit</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>
    );
}
