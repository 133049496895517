import React from 'react';
import { connect } from 'react-redux';
import { DomainName } from '../../_config/index';
import { alertActions, userActions } from '../../_actions';
import moment from 'moment-timezone';
import RulesNotification from "../../components/RulesNotification/RulesNotification";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoCaretBackCircle } from "react-icons/io5";
import BetCloseAlert from '../../components/CloseBetAlert/CloseBetAlert';
import ChartLogo from "./rising.png"
import { FaPlay } from "react-icons/fa";
// import { FaWhatsapp } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { IoMdAddCircleOutline } from "react-icons/io";
import Loader from '../../components/Loader/Loader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { IoArrowBack } from "react-icons/io5";


class BrandsList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      notification: false,
      editStake: false,
      timeDifference: null,
      isPastClosingTime: false
    }
  }

  componentDidMount() {
    // const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    // if (!hasSeenPopup) {
    //   this.setState({ notification: true });
    //   localStorage.setItem('hasSeenPopup', true);
    // }
    let data =
    {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 100
    }

    this.props.dispatch(userActions.getAllSlider());
    this.props.dispatch(userActions.getAllAppSetting());
    this.props.dispatch(userActions.getAllBrandForAdmin(data));
    this.props.dispatch(userActions.getProfile())
    // this.intervalId = setInterval(() => {
      // this.props.dispatch(userActions.getGamesList(data));

    // }, 2000);
  }

  // componentWillUnmount() {

  //   clearInterval(this.intervalId);
  // }


  navigateGameDetailsPage = (data) => {
    const closingTimeInMillis = data.closingTime;
    const currentTimeInMillis = new Date().getTime();

    if (currentTimeInMillis < closingTimeInMillis) {
      this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)
    } else {
      // this.setState({ isPastClosingTime: true });
      this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)
    }
  }

  handleCloseAlert = () => {
    this.setState({ isPastClosingTime: false });
  };

  handleCloseNotification = () => {
    this.setState({ notification: false });
    localStorage.setItem('hasSeenPopup', true);
  };

  render() {
    const { activeTab, notification, isPastClosingTime } = this.state;
    let { users } = this.props;
    let { totalGame, loading, gameList, brandData
      , currentTimeApi, appSettingData, slideData } = users;


    // const currentTimeApis = moment.utc(currentTimeApi * 1000).format("hh:mm A");
    const currentTimeApis = moment.utc(currentTimeApi * 1000).tz('Asia/Kolkata').format("hh:mm A");
    let recentList = [{
      name: "Sridevi",
      closingTime: 211331313131,
      openTime: 1131313131312
    }]

    var settings = {
      button: false,
      dots: true,
      slickPrev: false,
      slickNext: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };


    return (

      <>
        {/* <div className='w-full ' style={{ backgroundImage: "url(/images/bg-all.jpg)" }}> */}
        <div className='w-full ' style={{ backgroundColor: "#fff" }}>
          <Loader loading={loading} />
          <div className=' flex space-x-1'>
            <div className="lg:flex w-full relative  space-x-1">
              <div className=' text-3xl absolute text-[#3e44d5]'>

                <IoArrowBack className=' cursor-pointer' onClick={() => this.props.history.goBack()} />
              </div>
              <div className="w-full space-y-1 h-[98vh] overflow-y-auto pb-20">

                <div className=' overflow-hidden  w-full'>
                  {/* <marquee class="text-black font-bold mt-1  bg-[#f97941] text-sm py-2 bgheader">Welcome To  Profitplay.win - Your Ultimate Matka Experience! contactus  {appSettingData && appSettingData.length > 0 && appSettingData.filter((e) => e.key === "MobileNumber")[0]?.value} </marquee> */}
                  <div className=' bg-[#3e44d5]'>
                    {/* <SimpleSlider slideData={slideData} /> */}
                  </div>

                  <div className='space-y-5 lg:space-y-10  pt-5 px-4'>

                    {/* <div className=' w-full border-4 flex md:pr-12  shadow-orange-500 shadow-md justify-center items-center rounded-lg border-[#F97941] bg-[#3E44D5] text-[#F97941]  my-3 p-4 text-2xl  uppercase '>
                      <span className=' '>

                      </span>
                      <span style={{ fontFamily: "Algerian" }} className=' flex-1 text-center md:text-[50px] text-lg font-bold'>
                        Brands
                      </span>
                    </div> */}


                    {brandData
                      && brandData
                        .length > 0 ? brandData
                          .map((element, index) => {
                            // console.log("-------->",element)
                            return (
                              // <Link className='' to={`/app/dashboard/${element?._id}`}>
                              <div className=' w-full mt-8 border-4 flex md:pr-12  shadow-orange-500 shadow-md justify-center items-center rounded-lg border-[#F97941] bg-[#3E44D5] text-[#F97941]  my-3 p-4 text-2xl  uppercase '>
                                <span className=' '>
                                  <Link to={`/app/fatafat/${element?.name}/${element?._id}`}>
                                  {/* /app/fatafat/:BrandName/:id */}
                                    <img src={ChartLogo} className=' w-14 h-14' />
                                  </Link>
                                </span>
                                <span style={{ fontFamily: "Algerian" }} className=' flex-1 text-center md:text-[50px] text-lg font-bold'>
                                  {element?.name}
                                </span>
                                <Link to={`/app/dashboard/${element?._id}`} className=' bg-pink-500 text-green pulse p-3 text-white text-2xl font-bold rounded-full'>
                                  <FaPlay />
                                </Link>






                              </div>
                              // </Link>
                            )
                          }) : null}
                    {/* {gameList
                      && gameList
                        .length > 0 ? gameList
                          .map((element, index) => {


                            const openingTime = moment()
                              .hours(parseInt(element && element.openTime / 3600))
                              .minutes(parseInt((element && element.openTime % 3600) / 60))
                              .seconds(parseInt(element && element.openTime % 60))
                              .milliseconds(0);

                            const closingTime = moment()
                              .hours(parseInt(element && element.closingTime / 3600))
                              .minutes(parseInt((element && element.closingTime % 3600) / 60))
                              .seconds(parseInt(element && element.closingTime % 60))
                              .milliseconds(0);

                            const OpenBetTime = openingTime.format("hh:mm A");

                            const CloseBetTime = closingTime.format("hh:mm A");


                            const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(moment(CloseBetTime, "hh:mm A"));
                            if (element?.shortName != "bazi1" && element.shortName != "bazi2" && element.shortName != "bazi3" && element.shortName != "bazi4" && element.shortName != "bazi5" && element.shortName != "bazi6" && element.shortName != "bazi7" && element.shortName != "bazi8") {
                              return (
                                <div className='w-full  shadow-md  border-2 text-[#F97941] rounded-3xl bg-[#3E44D5] '>



                                  <div>

                                    <div className=' flex justify-between items-center  p-3'>
                                      <div className='flex  h-10 justify-center items-center flex-col'>
                                        <div className='text-[9px] md:text-base mt-2 font-bold'>Chart</div>

                                        <Link to={`/app/charts/${element?._id}/${element?.name}`}>
                                          <img src={ChartLogo} className=' w-14 h-14' />
                                        </Link>
                                        <div className='text-[9px] md:text-base mt-2 font-bold'>Open {OpenBetTime && OpenBetTime || ''}</div>

                                      </div>



                                      <div className=' flex h-10 flex-col justify-center items-center'>
                                        <div className='uppercase text-base  font-bold  lg:text-xl'>{element && element.name ? element.name : ""}</div>
                                        <div className='text-lg  py-4   font-semibold'>{element && element?.market && element?.market?.openNumber || "***"}-{element && element?.market && element?.market?.jodiNumber && element?.market?.jodiNumber.split("")[0] || "*"}{element && element?.market && element?.market?.jodiNumber && element?.market?.jodiNumber.split("")[1] || "*"}-{element && element?.market && element?.market?.closeNumber || "***"}</div>
                                        {isBefore && !element?.market?.closeNumber ? <div className='w-full text-[#38EF7D]  uppercase font-bold text-center'>Market Open</div>
                                          : <div className='w-full text-[#FE0000] font-bold  uppercase  text-center'>Market Close</div>
                                        }
                                      </div>


                                      <div className=' flex justify-center flex-col gap-3 items-center'>
                                        <div className='uppercase text-base  font-bold  lg:text-xl'>{" "} </div>

                                        <div className={`flex justify-center rounded-full  text-white ${isBefore && !element?.market?.closeNumber ? 'bg-[#198f51]' : 'bg-[#3e44d5]'} w-12 h-12  items-center`}>
                                          {isBefore && !element?.market?.closeNumber ? <FaPlay className=' text-xl cursor-pointer' onClick={() => this.navigateGameDetailsPage(element)} />
                                            : <FaPause className=' text-xl ' />}
                                        </div>
                                        <div className='text-[9px] md:text-base mt-2 font-bold  items-end '>Close {CloseBetTime && CloseBetTime || ''}
                                        </div>

                                      </div>

                                    </div>

                                  </div>



                                </div>
                              )
                            }

                          }) : null} */}


                  </div>

                </div>
              </div>
            </div>
          </div>
          {this.state.isPastClosingTime ?
            <BetCloseAlert
              handleCloseAlert={this.handleCloseAlert} /> : null}

          {notification &&
            <RulesNotification
              handleCloseNotification={this.handleCloseNotification}
              DomainName={DomainName}
            />}
        </div>
        {/* <Footer props={this.props} /> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(BrandsList);
