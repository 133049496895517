import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import logofrom from "./pwtw.png"
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import background from "./background.png"
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { IoLogoAndroid } from "react-icons/io";
class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      passtype: "password",
      visible: false,
    };
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let data = {
        userName: this.state.fieldslogin.username,
        password: this.state.fieldslogin.password,
      };
      this.props.dispatch(userActions.login(data, this.props));
    }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"] || fieldslogin["username"].trim() == "") {
      formIsValid = false;
      errorslogin["username"] = "Username is required.";
    } else if (fieldslogin["username"].includes(" ")) {
      formIsValid = false;
      errorslogin["username"] = "Spaces are not allowed in the  Name.";
    }
    //password
    if (!fieldslogin["password"] || fieldslogin["password"].trim() == "") {
      formIsValid = false;
      errorslogin["password"] = "Password is required.";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  handleVisible = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible
    }));
  }



  render() {
    const { authentication } = this.props
    let { loading } = authentication ? authentication : {}

    return (
      <div className=" bg-gray-400   flex justify-center items-center ">
        <Loader loading={loading} />
        {/* <div className="flex  overflow-hidden  h-screen"> */}
          <div style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat' }} className="flex  bg-cover overflow-hidden    flex-col         h-screen     py-24 md:w-[550px]  w-[500px] p-3   justify-center items-center  ">
            <div className="  flex w-full justify-center items-end flex-1 ">

              <img src={logofrom} className=" w-36 h-36" />
            </div>

            <div className=" flex-1 w-full flex justify-center  items-center flex-col ">
              <h1 className="flex items-center justify-center  md:text-3xl text-xl  text-[#3e44d5] font-bold ">Log in</h1>
              <h1 className="flex items-center justify-center md:text-2xl text-lg text-stone-700"><span className="text-[#3e44d5]">Welcome to</span> <spna style={{ fontFamily: 'Algerian' }} className="text-[#F65C24] px-1  uppercase font-bold"> Profit Play</spna></h1>
              <div className="w-96 p-4">

                <div className=" w-full flex flex-col">
                  <input
                    type="text"
                    name="username"
                    id="username"
                    value={this.state.fieldslogin.username}
                    className="w-full md:px-2 md:py-3 px-1 py-2 focus:outline-none text-lg  border-2 border-[#3E44D5]  rounded-lg  "
                    placeholder="Username"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin &&
                    this.state.errorslogin["username"] ? (
                    <div className="text-red-500 text-left text-xs">
                      {this.state.errorslogin["username"]}
                    </div>
                  ) : null}
                </div>
                <div className="w-full mt-3 relative">
                  <input
                    type={this.state.visible ? "text" : "password"}
                    name="password"
                    id="password"
                    value={this.state.fieldslogin.password}
                    className="w-full md:px-2 md:py-3 px-1 py-2 focus:outline-none text-lg  rounded-lg border-[#3E44D5]  border-2 " placeholder="Password"
                    onChange={this.inputChange}
                  />
                  {this.state.visible ? <MdOutlineRemoveRedEye onClick={this.handleVisible} className=" absolute text-[#F65C2B] top-5 cursor-pointer right-2" /> : <FaRegEyeSlash onClick={this.handleVisible} className=" absolute top-5 text-[#F65C2B] cursor-pointer right-2" />}
                  {this.state.errorslogin &&
                    this.state.errorslogin["password"] ? (
                    <div className="text-red-500 whitespace-nowrap text-left text-xs">
                      {this.state.errorslogin["password"]}
                    </div>
                  ) : null}
                </div>


                <div className="w-full flex justify-center items-center">
                  <button type="button" onClick={this.loginSubmit}
                    className="flex justify-center items-center py-2 bg-[#3e44d5] w-full text-white font-semibold  text-lg  rounded-lg mt-5">
                    <span className="tracking-wider ">Login</span>
                  </button>
                </div>
                <div className=" w-full bg-[#3e44d5] p-2  flex justify-center items-center text-white mt-1 rounded-md ">

                  <Link className="" to={"#"}>Download App  </Link>
                  <IoLogoAndroid className=" text-2xl" />
                </div>

              </div>
            </div>


          </div>
        {/* </div> */}
        {/* <Footer /> */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent, } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users,
    authentication
  };
}
export default connect(mapStateToProps)(Login);
