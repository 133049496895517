import React from 'react';
import { connect } from 'react-redux';
import { DomainName } from '../../_config/index';
import { userActions } from '../../_actions';
import moment from 'moment-timezone';
import RulesNotification from "../../components/RulesNotification/RulesNotification";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPause } from "react-icons/fa";
import BetCloseAlert from '../../components/CloseBetAlert/CloseBetAlert';
import { IoCaretBackCircle } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import Loader from '../../components/Loader/Loader';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      notification: false,
      editStake: false,
      timeDifference: null,
      isPastClosingTime: false
    }
  }

  componentDidMount() {
    this.props.dispatch(userActions.clear());
    this.props.dispatch(userActions.getProfile())
    this.props.dispatch(userActions.getAllSlider());
    this.props.dispatch(userActions.getAllAppSetting());
    this.props.dispatch(userActions.getBrandByGameIdForAdmin(this.props.match.params));
  }

  componentWillUnmount() {
  }



  navigateGameDetailsPage = (data) => {
    const closingTimeInMillis = data.closingTime;
    const currentTimeInMillis = new Date().getTime();

    if (currentTimeInMillis < closingTimeInMillis) {
      this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)
    } else {
      // this.setState({ isPastClosingTime: true });
      this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)
    }
  }

  handleCloseAlert = () => {
    this.setState({ isPastClosingTime: false });
  };

  handleCloseNotification = () => {
    this.setState({ notification: false });
    localStorage.setItem('hasSeenPopup', true);
  };

  render() {
    const { activeTab, notification, isPastClosingTime } = this.state;
    let { users } = this.props;
    let { totalGame, loading, gameList,
      BrandInfos
      , slideData, currentTimeApi, appSettingData, getProfile } = users;
    let { blockedGames } = getProfile ? getProfile : {}

    // const currentTimeApis = moment.utc(currentTimeApi * 1000).tz('Asia/Kolkata').format("hh:mm A");
    function formatSecondsToTime(totalSeconds) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      const timeString = moment().startOf('day')
        .add(hours, 'hours')
        .add(minutes, 'minutes')
        .add(seconds, 'seconds')
        .format('hh:mm A');

      return timeString;
    }
    const currentTimeApis = formatSecondsToTime(currentTimeApi)

    let recentList = [{
      name: "Sridevi",
      closingTime: 211331313131,
      openTime: 1131313131312
    }]

    var settings = {
      button: false,
      dots: true,
      slickPrev: false,
      slickNext: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };




    let blockedGamesIds = blockedGames&&blockedGames.length>0&&blockedGames.map(e=>e?.games)?.filter(games=>games!==undefined)?.flat(Infinity)

    return (

      <>
        {/* <div className='w-full ' style={{ backgroundImage: "url(/images/bg-all.jpg)" }}> */}
        <div className='w-full ' style={{ backgroundColor: "#fff" }}>
          <Loader loading={loading} />
          <div className=' flex space-x-1 relative'>
            <div className=' text-3xl top-5 absolute text-[#3e44d5]'>

              <IoArrowBack className=' cursor-pointer' onClick={() => this.props.history.goBack()} />
            </div>
            <div className="lg:flex w-full  space-x-1">
              <div className="w-full space-y-1 h-[98vh] overflow-y-auto md:pb-20">

                <div className=' overflow-hidden  w-full'>

                  {/* <marquee class="text-black font-bold  bg-[#F97941] text-sm py-2 bgheader">Welcome To  Lucky Bazar - Your Ultimate Matka Experience! contactus  {appSettingData && appSettingData.length > 0 && appSettingData.filter((e) => e.key === "MobileNumber")[0]?.value} </marquee> */}


                  <div className=' flex mt-10  flex-col justify-center items-center gap-2 pb-12  px-4'>


                    {BrandInfos && BrandInfos.length > 0 ? BrandInfos.map((element, index) => {


                      const closeCloseBidTime = moment.duration(element?.betOpenTime, 'seconds').asMinutes()


                      const openingTime = moment()
                        .hours(parseInt(element && element.openTime / 3600))
                        .minutes(parseInt((element && element.openTime % 3600) / 60))
                        .seconds(parseInt(element && element.openTime % 60))
                        .milliseconds(0);

                      const closingTime = moment()
                        .hours(parseInt(element && element.closingTime / 3600))
                        .minutes(parseInt((element && element.closingTime % 3600) / 60))
                        .seconds(parseInt(element && element.closingTime % 60))
                        .milliseconds(0);

                      const OpenBetTime = openingTime.format("hh:mm A");

                      const CloseBetTime = closingTime.format("hh:mm A");

                      const getCurrentTime = () => {
                        const now = moment();
                        return now.format('hh:mm:A');
                      };
                      const currentTimeApis = getCurrentTime()

                      const thirtyMinutesBeforeClosing = closingTime.clone().subtract(closeCloseBidTime, 'minutes');

                      const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(moment(thirtyMinutesBeforeClosing, "hh:mm A"));
               
                      if(!Array.isArray(blockedGamesIds)&&blockedGamesIds==false){
                        return (

                          <div onClick={() => this.navigateGameDetailsPage(element)} className='w-full border  rounded-3xl text-[#F97941] bg-[#3E44D5] '>

                            <div className=' flex justify-between items-center p-2'>

                              <div className=' flex flex-col justify-center rounded-full  items-start'>
                                <img src={element?.image} className='md:h-24 md:w-24 h-20 w-20 rounded-full' />
                                {/* <span className='uppercase text-xl  font-bold '>{element && element.name ? element.name : ""}</span> */}
                              </div>
                              <div className=' flex justify-center items-center  flex-col'>
                                <div className='md:text-3xl text-2xl mt-2 font-bold'>{OpenBetTime && OpenBetTime || ''}</div>
                          
                                <h2 className='text-lg font-semibold'>{element && element?.market && element?.market?.openNumber.toString()=="0"?"000":element?.market?.openNumber.toString() || "***"}-{element && element?.market && element?.market?.jodiNumber && element?.market?.jodiNumber.split("")[0] || "*"}</h2>
                                {isBefore &&element?.onOf && element?.market?.openNumber ==null ? <div className='w-full text-[#38EF7D]  uppercase font-bold text-center'>Betting Running</div>
                                  : <div className='w-full text-[#FE0000] font-bold  uppercase text-center'>Betting Closed</div>
                                }
                              </div>

                              <div className=' flex  justify-center gap-3 items-center'>

                              

                                <div className={`flex justify-center rounded-full  text-white ${isBefore &&element?.onOf&& element?.market?.openNumber ==null ? 'bg-[#198f51]' : 'bg-[#F97941]'} w-12 h-12  items-center`}>
                                  {isBefore&& element?.onOf && element?.market?.openNumber ==null ? <FaPlay className=' text-xl cursor-pointer' />
                                    : <FaPause className=' text-xl ' />}
                                </div>

                              </div>

                            </div>
                          </div>

                        )
                      }else if(Array.isArray(blockedGamesIds)&&!blockedGamesIds?.includes(element._id)){
                        return (

                          <div onClick={() => this.navigateGameDetailsPage(element)} className='w-full border  rounded-3xl text-[#F97941] bg-[#3E44D5] '>

                            <div className=' flex justify-between items-center p-2'>

                              <div className=' flex flex-col justify-center rounded-full  items-start'>
                                <img src={element?.image} className='md:h-24 md:w-24 h-20 w-20 rounded-full' />
                                {/* <span className='uppercase text-xl  font-bold '>{element && element.name ? element.name : ""}</span> */}
                              </div>
                              <div className=' flex justify-center items-center  flex-col'>
                                <div className='md:text-3xl text-2xl mt-2 font-bold'>{OpenBetTime && OpenBetTime || ''}</div>
                                <h2 className='text-lg font-semibold'>{element && element?.market && element?.market?.openNumber.toString() || "***"}-{element && element?.market && element?.market?.jodiNumber && element?.market?.jodiNumber.split("")[0] || "*"}</h2>
                                {isBefore &&element?.onOf && element?.market?.openNumber ==null ? <div className='w-full text-[#38EF7D]  uppercase font-bold text-center'>Betting Running</div>
                                  : <div className='w-full text-[#FE0000] font-bold  uppercase text-center'>Betting Closed</div>
                                }
                              </div>

                              <div className=' flex  justify-center gap-3 items-center'>


                                <div className={`flex justify-center rounded-full  text-white ${isBefore&&element?.onOf && element?.market?.openNumber ==null ? 'bg-[#198f51]' : 'bg-[#F97941]'} w-12 h-12  items-center`}>
                                  {isBefore&&element?.onOf && element?.market?.openNumber ==null ? <FaPlay className=' text-xl cursor-pointer' />
                                    : <FaPause className=' text-xl ' />}
                                </div>

                              </div>

                            </div>
                          </div>

                        )
                      }               
                    }) : null}
                  </div>

                </div>
              </div>
            </div>
          </div>
          {this.state.isPastClosingTime ?
            <BetCloseAlert
              handleCloseAlert={this.handleCloseAlert} /> : null}

          {notification &&
            <RulesNotification
              handleCloseNotification={this.handleCloseNotification}
              DomainName={DomainName}
            />}
        </div>
        {/* <Footer props={this.props} /> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(Dashboard);
