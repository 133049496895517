import React from 'react';
import { connect } from 'react-redux';
import { betActions, userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Sidebar from "../../components/Sidebar/Sidebar";


class WinHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 10,
    }
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),





    }

    this.props.dispatch(betActions.getWinningListByUserId(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData =
    {


      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      pageNo: 1,
      size: 200,

    }

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }


  getBatHistory = () => {
    let data = {
      "fromDate": this.state.startDate,
      "toDate": this.state.endDate,
      "gameId": this.state.sportsId,
    }

    this.props.dispatch(betActions.getWinningListByUserId(data));
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };


  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        "fromDate": this.state.startDate,
        "toDate": this.state.endDate,





      }
      this.props.dispatch(betActions.getWinningListByUserId(pagination));
    })

  }

  render() {

    let { bet, users } = this.props;
    let { betList, loading, betLoading, totalBet, winList } = bet;
    let { totalGame, gameList } = users;
    const today = new Date().toISOString().split('T')[0];
    return (

      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className='bg-gray-200 w-full min-h-screen flex py-10'>
          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className='lg:w-[90%] w-full  space-y-4 mx-auto'>
            <div className=' text-xl font-bold'>Win History</div>
            <div className='  border grid grid-cols-1 lg:grid-cols-7 divide-x  lg:divide-y-0'>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>From Date</label>
                <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.startDate} onChange={this.inputChangeStartDate} />
              </div>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>To Date</label>
                <input type="date" max={today} className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.endDate} onChange={this.inputChangeEndDate}
                />
              </div>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>Game Name</label>
                <select onChange={this.handleChangeSelect} name="sports_id" className=' w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none block'>
                  <option value="">All</option>
                  {gameList && gameList.length > 0 ? gameList.map((element, index) => (
                    <option value={element && element._id} className='capitalize '>{element && element.name}</option>
                  )) : null}
                </select>
              </div>
              <div className='col-span-1 flex flex-col  justify-end items-end p-4'>
                <label className=''> </label>
                <button onClick={() => this.getBatHistory()} className='py-1.5 px-4 bg-[#3e44d5] text-white text-[0.8125rem] rounded'>Submit</button>
              </div>
            </div>
            <div className="overflow-hidden ">
              <div className="max-w-full overflow-auto ">
                <div className="inline-block min-w-full ">
                  <div className="overflow-hidden w-full ">
                    <div className=' w-full mb-10  flex flex-wrap gap-5 border-2 p-2'>


                      {winList && winList.length > 0 && winList.map((element, index) => {

                        return (
                          <div className=' flex  w-full flex-col  text-xs border-gray-500 rounded-r-md border p-4'>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 uppercase mb-1'><div>S.No.</div>  {this.state.offset + index + 1}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 uppercase mb-1'><div>Market</div> {element && element.market && element.market[0] ? element.market[0] : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 uppercase mb-1'><div>TYPE</div>{element && element.game && element.game[0] ? element.game[0] : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 uppercase mb-1'><div>NUMBER</div>{element && element.betNumber ? element.betNumber : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 uppercase mb-1'><div>Point</div> {element && element.amount ? element.amount : "-"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 uppercase mb-1'><div>BID TYPE</div>{element && element.isOpen ? "Open" : "Close"}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 uppercase mb-1'><div>Date</div>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A")}</div>
                            <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 uppercase mb-1'><div>Profit/Loss</div>  {element && element.betStatus == 0 && <div className=' text-yellow-500'>Pending</div>}
                              {element && element.betStatus == 1 && <div className=' text-green-800'>+{element?.winAmount}</div>}
                              {element && element.betStatus == 2 && <div className=' text-red-700'>-{element?.lossAmount}</div>}</div>
                          </div>
                        )
                      })}
                      {/* {

                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            totalBet && totalBet > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={totalBet / 10}
                                marginPagesDisplayed={5}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                      } */}


                    </div>
                    {/* <div class="relative overflow-x-auto rounded-lg">
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 rounded-md ">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              S.No.
                            </th>
                            <th scope="col" class="px-6 py-3">
                              Market
                            </th>
                            <th scope="col" class="px-6 py-3">
                              Game
                            </th>
                            <th scope="col" class="px-6 py-3">
                              Number
                            </th>
                            <th scope="col" class="px-6 py-3">
                              Coins
                            </th>
                            <th scope="col" class="px-6 py-3">
                              Bid Type
                            </th>
                            <th scope="col" class="px-6 py-3">
                              Date
                            </th>
                            <th scope="col" class="px-6 py-3">
                              P/L
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {winList && winList.length > 0 ? (
                            winList.map((element, index) => {
                              console.log(element)
                              return (
                                <tr class="bg-white border-b  ">
                                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    {this.state.offset + index + 1}
                                  </th>
                                  <td class="px-6 py-4">
                                    {element && element.market && element.market[0] ? element.market[0] : "-"}
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap">
                                    {element && element.game && element.game[0] ? element.game[0] : "-"}
                                  </td>
                                  <td class="px-6 py-4">
                                    {element && element.betNumber ? element.betNumber : "-"}
                                  </td>
                                  <td class="px-6 py-4">
                                    {element && element.amount ? element.amount : "-"}
                                  </td>
                                  <td class="px-6 py-4">
                                    {element && element.isOpen ? "Open" : "Close"}
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap">
                                    {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td class="px-6 py-4">
                                    {element && element.betStatus == 0 && <div className=' text-yellow-500'>--</div>}
                                    {element && element.betStatus == 1 && <div className=' text-green-800'>+{element?.winAmount}</div>}
                                    {element && element.betStatus == 2 && <div className=' text-red-700'>-{element?.lossAmount}</div>}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>

                                No Data Found
                              </td>

                            </tr>
                          )}




                        </tbody>
                      </table>
                    
                    </div> */}










                  </div>
                </div>






              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet
  };
}

export default connect(mapStateToProps)(WinHistory);

