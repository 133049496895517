import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {  bankActions, userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { IoBarChartSharp } from "react-icons/io5";
import { useParams } from 'react-router';
import Loader from '../../components/Loader/Loader';
const Chartrr = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { appSettingData,gameList,chartdata ,loading } = users;
  let parmas = useParams()

console.log("parmasparmas")

  useEffect(() => {
   
    dispatch(bankActions.calander({ gameId: parmas?.gameId }));
  }, [parmas])

  console.log(users)


  return (
    <>
      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='flex space-x-1 p-1 h-[90vh]  overflow-y-scroll'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <Loader loading={loading}/>
          <div className='w-full'>
            <IoCaretBackCircleSharp onClick={() => history.goBack()} className=' text-3xl text-[#3e44d5] cursor-pointer m-1' />
      
            <div className=' bg-white p-5 flex flex-col gap-4 rounded-md mb-10 '>
     


            <div>
                        {/* <a className=' flex bg-[#3e44d5] justify-center items-center w-40 mx-auto mb-3 py-2 px-1 text-white font-bold rounded-md' href='#top' id='bottom'> GO TO BOTTOM  </a> */}
                        <div className='overflow-hidden overflow-x-auto'>
                          <table class="min-w-full text-xs md:text-base  divide-y divide-gray-200 ">
                            <thead>
                              <tr class="bg-gray-50">
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">Date</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">MON</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">TUE</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">WED</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">THU</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">FRI</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">SAT</th>
                                <th  class=" text-center md:text-base border  text-xs font-medium  text-[#17273B] uppercase tracking-wider">SUN</th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              {chartdata && chartdata.length > 0 && chartdata ? chartdata?.map((week, index) => {
                                const startDate = new Date(week.rWeek);
                     
                                const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                       
                                const formattedStartDate = startDate.toISOString().split('T')[0];
                                const formattedEndDate = endDate.toISOString().split('T')[0];

                                return (
                                  <tr key={index} class="bg-white border">

                                    <td class="px-1 whitespace-nowrap border">
                                      <div className=' flex flex-col text-xs  md:text-base justify-center items-center'>
                                        <div>
                                          {formattedStartDate}
                                        </div>
                                        to
                                        <div>
                                          {formattedEndDate}
                                        </div>
                                      </div>

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.MON?.openNumber&&week?.MON?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.MON?.openNumber&&week?.MON?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.MON?.openNumber&&week?.MON?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.MON?.jodiNumber&&week?.MON?.jodiNumber.toString()[0] || "*"} {week?.MON?.jodiNumber&&week?.MON?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.MON?.closeNumber&&week?.MON?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.MON?.closeNumber&&week?.MON?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.MON?.closeNumber&&week?.MON?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.TUE?.openNumber&&week?.TUE?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.TUE?.openNumber&&week?.TUE?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.TUE?.openNumber&&week?.TUE?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.TUE?.jodiNumber&&week?.TUE?.jodiNumber.toString()[0] || "*"} {week?.TUE?.jodiNumber&&week?.TUE?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.TUE?.closeNumber&&week?.TUE?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.TUE?.closeNumber&&week?.TUE?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.TUE?.closeNumber&&week?.TUE?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.WED?.openNumber&&week?.WED?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.WED?.openNumber&&week?.WED?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.WED?.openNumber&&week?.WED?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.WED?.jodiNumber&&week?.WED?.jodiNumber.toString()[0] || "*"} {week?.WED?.jodiNumber&&week?.WED?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.WED?.closeNumber&&week?.WED?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.WED?.closeNumber&&week?.WED?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.WED?.closeNumber&&week?.WED?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.THU?.openNumber&&week?.THU?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.THU?.openNumber&&week?.THU?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.THU?.openNumber&&week?.THU?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.THU?.jodiNumber&&week?.THU?.jodiNumber.toString()[0] || "*"} {week?.THU?.jodiNumber&&week?.THU?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.THU?.closeNumber&&week?.THU?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.THU?.closeNumber&&week?.THU?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.THU?.closeNumber&&week?.THU?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.FRI?.openNumber&&week?.FRI?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.FRI?.openNumber&&week?.FRI?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.FRI?.openNumber&&week?.FRI?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.FRI?.jodiNumber&&week?.FRI?.jodiNumber.toString()[0] || "*"} {week?.FRI?.jodiNumber&&week?.FRI?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.FRI?.closeNumber&&week?.FRI?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.FRI?.closeNumber&&week?.FRI?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.FRI?.closeNumber&&week?.FRI?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.SAT?.openNumber&&week?.SAT?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.SAT?.openNumber&&week?.SAT?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.SAT?.openNumber&&week?.SAT?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.SAT?.jodiNumber&&week?.SAT?.jodiNumber.toString()[0] || "*"} {week?.SAT?.jodiNumber&&week?.SAT?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.SAT?.closeNumber&&week?.SAT?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.SAT?.closeNumber&&week?.SAT?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.SAT?.closeNumber&&week?.SAT?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>
                                    <td class="px-1 whitespace-nowrap border">
                                      {<div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                        <div>
                                        <div>{week?.SUN?.openNumber&&week?.SUN?.openNumber.toString()[0] || "*"}</div>
                                        <div>{week?.SUN?.openNumber&&week?.SUN?.openNumber.toString()[1] || "*"}</div>
                                        <div>{week?.SUN?.openNumber&&week?.SUN?.openNumber.toString()[2] || "*"}</div>
                                        
                                        
                                        
                                        </div>
                                        <div className=' text-red-500'>{week?.SUN?.jodiNumber&&week?.SUN?.jodiNumber.toString()[0] || "*"} {week?.SUN?.jodiNumber&&week?.SUN?.jodiNumber[1] || "*"}</div>
                                        <div className=' '>
                                        <div>{week?.SUN?.closeNumber&&week?.SUN?.closeNumber.toString()[0] || "*"}</div>
                                        <div>{week?.SUN?.closeNumber&&week?.SUN?.closeNumber.toString()[1] || "*"}</div>
                                        <div>{week?.SUN?.closeNumber&&week?.SUN?.closeNumber.toString()[2] || "*"}</div>
                                        
                                        </div>
                                      </div>}

                                    </td>


                                  </tr>
                                )

                              }):<td> No Data Found</td>}
                            </tbody>
                          </table>
                        </div>
                  
                        {/* <a  className=' flex bg-[#3e44d5] justify-center items-center w-40 mx-auto mt-3 py-2 px-1 text-white font-bold rounded-md' href='#bottom' id='top'> 
                        GO TO Top
                         </a> */}
                      </div>


            </div>




          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(Chartrr));